import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-contact-add',
  templateUrl: './contact-add.component.html',
  styleUrls: ['./contact-add.component.scss']
})
export class ContactAddComponent implements OnInit {
contactAddForm:FormGroup;
  constructor(private formBuilder:FormBuilder, private router:Router, 
    public alertService:AlertService, public util: UtilService,
   
    public loaderService:LoaderService,
     public httpService:HttpServiceService) { }
  ngOnInit() {
    this.contactAddForm = this.formBuilder.group({
      contactinformation: ['',[]],
      contactnumber: ['', []],
      contactaddress: ["", []],
      timings: ["",[]],
      contactemail: ["",[]],
	  
    });
  }

     
  
  submit(data){
   
  this.loaderService.showLoader('Please wait while we authenticate').then(()=>{

    this.httpService.postApi(data,`/client/add_contact_details?information=${data.contactinformation}&address=${data.contactaddress}&timings=${data.timings}&phone=${data.contactnumber}&email=${data.contactemail}`).subscribe((res) => {
      console.log(res);
      if(res["success"]){
        this.alertService.presentAlert('Success','Successfully added','Okay');
      }else {
        this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
      }
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })
    
 
}
}


