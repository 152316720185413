import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-company-overview-edit',
  templateUrl: './company-overview-edit.component.html',
  styleUrls: ['./company-overview-edit.component.scss']
})
export class CompanyOverviewEditComponent implements OnInit {

  companyoverviewForm: FormGroup;
  file: any;
  comapnyOverViewlocaldata:any = {};

  constructor(private formBuilder: FormBuilder, private router: Router,
    public util: UtilService,
    public httpService: HttpServiceService, public alertService: AlertService,
    public loaderService: LoaderService) { }

  ngOnInit() {
    this.companyoverviewForm = this.formBuilder.group({
      description: [''],
      image: ['']
    });
    this.comapnyOverViewlocaldata = {}
    this.comapnyOverViewlocaldata =  JSON.parse(localStorage.getItem("CompanyOverViewEdit"))
    this.comapnyOverViewlocaldata.image = "";
    console.log(this.comapnyOverViewlocaldata)
    this.companyoverviewForm.patchValue(this.comapnyOverViewlocaldata);

  }


  onFileSelect(event) {

    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  submit(data) {

    console.log(this.companyoverviewForm.value)
    this.loaderService.showLoader('Adding Please wait ..').then(() => {
      try {
        const formData = new FormData();

        // formData.append('description', this.companyoverviewForm.get('description').value);
        formData.append('company_overview_image', this.file);

        this.httpService.postApiForImageSending(formData, `/client/update_company_overview?description=${this.companyoverviewForm.get('description').value}&id=${this.comapnyOverViewlocaldata.id}`).subscribe((res) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('success', 'Successfully added', 'Okay');
            this.router.navigate(['/company-overview-list'])
          } else {
            this.alertService.presentAlert('Error', res["error"]["success"], 'Okay');
          }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }

}
