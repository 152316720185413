import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import * as moment from 'moment';

@Component({
  selector: 'app-client-status-report-list',
  templateUrl: './client-status-report-list.component.html',
  styleUrls: ['./client-status-report-list.component.scss']
})
export class ClientStatusReportListComponent implements OnInit {
statusJson:any=[]
status:any=[]
  certificates:any={}
  json: any=[];
  statuses:any=[]
  startDate
  projectStatus:any={}
  reneweldays
  serviceTypes:any=[]
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService,
    public loaderService: LoaderService) {

  }

  async loadData(){
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('Project/getAllProjects').subscribe(async(res: any) => {
         
          if (res.success) {
          //  console.log(res)
            this.json = res.success.project;
             console.log(this.json)
             await this.getcertificate();
             await this.getstatus();
             await this.getserviceType();
          }
          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }

  ngOnInit() {
    this.loadData();
  }
  add() {
    this.router.navigate(['/project-add'])
  }

  details(data) {
    localStorage.setItem('projectDetails', JSON.stringify(data));
    this.router.navigate(['/project-details'])
  }


  getcertificate()
  {
   
    return new Promise(resolve => {
      this.json.forEach(element => {
          this.httpService.postApi(element['id'], 'KCertificate/getbyprojectid/' + element['id']).subscribe(async(res: any) => {
            if (res.success.certificate!=null) {
            //  console.log(res)
          //   this.startDate=res.success.certificate.issueDate
          //   this.reneweldays=res.success.certificate.renewelDays
        
          // this.getExpirtDate(this.startDate,this.reneweldays)
               this.certificates[element['id']] = res.success.certificate
             
             // this.status.push(res.success.certificate)
            //  console.log( this.status)
            //  await this.getstatus()
            }
          });
      })
      resolve(1)      
    })
  }


 getExpirtDate(startDate,reneweldays)
 {
  var m=  moment(startDate).add('days',reneweldays).format('LL')
  return m;
  //console.log(m);
 }            

  getstatus()
  {
    
    return new Promise(resolve => {
    
      this.json.forEach(element => {
        element['status']=  element['status']==''?0:element['status'];
          this.httpService.postApi(element['status'], 'Status/getStatusBystatusid/' + element['status']).subscribe((res: any) => {
            if (res.success) {
             console.log(res)
               //this.projectStatus[element['status']] = res.success
              // console.log(this.projectStatus)
              this.statuses[element['status']]=res.success;
            }
          });
        
      })
      resolve(1) 
        
    })
  }

  getserviceType()
  {
    return new Promise(resolve => {
    
      this.json.forEach(element => {
        element['serviceType']=  element['serviceType']==''?0:element['serviceType'];
          this.httpService.postApi(element['serviceType'], 'Servicetype/getServiceById/' + element['serviceType']).subscribe((res: any) => {
            if (res.success) {
             console.log(res)
               //this.projectStatus[element['status']] = res.success
              // console.log(this.projectStatus)
              this.serviceTypes[element['serviceType']]=res.success.servicetype;
            }
          });
        
      })
      resolve(1) 
        
    })
  }
}
