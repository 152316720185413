import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-inbox-edit',
  templateUrl: './inbox-edit.component.html',
  styleUrls: ['./inbox-edit.component.scss']
})
export class InboxEditComponent implements OnInit {

  inboxForm: FormGroup;
  editDetails;
  constructor(private formBuilder: FormBuilder, private router: Router,
    public util: UtilService,
    public httpService: HttpServiceService, public alertService: AlertService,
    public loaderService: LoaderService) {

  }
  clients:any
  ngOnInit() {
    this.inboxForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      msg: ['', [Validators.required]],
      servicetype: ['', []],
      status: ['1', []],
      inbox_attachment: ['', []],
    });
    this.loaderService.showLoader('Please wait while fetching ..').then(() => {

      this.editDetails = JSON.parse(localStorage.getItem('inboxEditData'))
      let status = this.editDetails.status == "Active" ? '1':'0';
      this.editDetails.status = status
      console.log(this.editDetails)
      this.inboxForm.patchValue(this.editDetails);
      this.loaderService.hideLoader();
    })
    this.httpService.getApi('Client/getAllClients').subscribe((res: any) => {
      this.clients = res.success.client;
      console.log( this.clients)
     });
    this.getServiceType();

  }

  submit(data) {
    data['createdAt'] = new Date().getTime();
    this.loaderService.showLoader('Updating Please wait ..').then(() => {
      try {
        const formData = new FormData();
        formData.append('inbox_attachment', this.file);
        this.httpService.postApi(formData, `/client/add_inbox?email=${data.email}&msg=${data.msg}&servicetype=${data.servicetype}&status=${status}&id=${this.editDetails.id}`).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('message', 'Successfully updated', 'Okay');
            this.router.navigate(['/inbox-list'])
          } else {
            this.alertService.presentAlert('Error', "Something went wrong !", 'Okay');
          }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }

  serviceTypeList: any = []
  getServiceType() {
    this.httpService.getApi('/Servicetype/getAllServices').subscribe((res: any) => {
      this.serviceTypeList = res.success.servicetype ? res.success.servicetype : [];
    });
  }

  file:any
  onFileSelect(event) {

    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  


}
