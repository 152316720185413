import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-home-edit',
  templateUrl: './home-edit.component.html',
  styleUrls: ['./home-edit.component.scss']
})
export class HomeEditComponent implements OnInit {

  homeForm: FormGroup;
  editDetails;
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
    public httpService:HttpServiceService, public alertService:AlertService,
    public loaderService:LoaderService) {
    
  }

  ngOnInit() {
    this.homeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
              cityName: ['', [Validators.required]],
            });
    this.loaderService.showLoader('Please wait while fetching ..').then(()=>{
    
    this.editDetails = JSON.parse(localStorage.getItem('editData'))
    this.editDetails.cityName = "";
    this.editDetails.cityName = this.editDetails.city_name ? this.editDetails.city_name : "";
    console.log(this.editDetails)
    this.homeForm.patchValue(this.editDetails);
    this.loaderService.hideLoader();
  })

  }

  submit(data) {
    data['createdAt']= new Date().getTime();
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data,`/home/home-edit?mission=${data.mission}&id=${data.id})`).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully updated','Okay');
            this.router.navigate(['/home-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }


}
