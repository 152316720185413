import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss']
})
export class ServiceDetailsComponent implements OnInit {
  service;
  constructor( private router:Router,
    public httpService:HttpServiceService, public alertService:AlertService,
    public util:UtilService,
    public loaderService:LoaderService) {
   
  }
  serviceData:any = {}
  ngOnInit() {
    this.service = JSON.parse(localStorage.getItem('ServiceDataDetsails'))
    this.service.name = this.service.name ? this.service.name : "";
    this.service.description = this.service.description ? this.service.description : ""
    this.service.link = this.service.link ? this.service.link : ""
    console.log(this.service)
  }

  toggleStatus(id,image,description,link){
    let data = {
      image:image,
      description:description,
      link:link
    }
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{  
        this.httpService.postApi( data, 'client/objective-edit' + id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["message"]) {
            this.alertService.presentAlert('message','Successfully updated','Okay');
            this.router.navigate(['/front-end/objectives/objective-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
  }

  delete(data) {
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'objective/remove/'+data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["message"]) {
            this.alertService.presentAlert('message','Successfully deleted','Okay');
            this.router.navigate(['/front-end/objectives/objective-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })

  }
  edit(data) {
    console.log(data)
    localStorage.setItem('editServiceData', JSON.stringify(data));
    this.router.navigate(['service-edit'])
  }
}
