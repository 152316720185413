import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-objective-details',
  templateUrl: './objective-details.component.html',
  styleUrls: ['./objective-details.component.scss']
})
export class ObjectiveDetailsComponent implements OnInit {
  objective;
  constructor( private router:Router,
    public httpService:HttpServiceService, public alertService:AlertService,
    public util:UtilService,
    public loaderService:LoaderService) {
    this.objective = JSON.parse(localStorage.getItem('objectiveDetails'))
  }

  ngOnInit() {
  }

  toggleStatus(id,question,answer){
    let data = {
      question:question,
      answer:answer
    }
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{  
        this.httpService.postApi( data, 'client/objective-edit' + id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["message"]) {
            this.alertService.presentAlert('message','Successfully updated','Okay');
            this.router.navigate(['/front-end/objectives/objective-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
  }

  delete(data) {
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, '/client/delete_objective?id='+data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["message"]) {
            this.alertService.presentAlert('message','Successfully deleted','Okay');
            this.router.navigate(['/front-end/objectives/objective-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })

  }
  edit(data) {
    console.log(data)
    localStorage.setItem('editData', JSON.stringify(data));
    this.router.navigate(['front-end/objectives/objective-edit'])
  }

  backToListList() {
    this.router.navigate(['/front-end/objectives/objective-list'])
  }
 
}
