import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-fire-quote-list',
  templateUrl: './fire-quote-list.component.html',
  styleUrls: ['./fire-quote-list.component.scss']
})
export class FireQuoteListComponent implements OnInit {
  clients: any = {}
  json: any=[];
  user:any={}
  allProjects:any={}
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService,
    public loaderService: LoaderService) {
      this.user = JSON.parse(localStorage.getItem('AlluserData'))
      console.log(this.user)
  }

  async loadData(){
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('Fquote/getAll').subscribe(async(res: any) => {
          
          if (res.success) {
            console.log(res)
            this.json = res.success.firequote;
             console.log(this.json)
             await this.getclient()
             await this.getAllProjects()
          }
          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }
  clientData:any = {};
  ngOnInit() {
    this.clientData =JSON.parse(localStorage.getItem('AlluserData'))
    if(this.user.isAdmin == 0){
      this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
        try{
          this.httpService.postApi({client:this.user.id},'Fquote/fireByID/' +this.user.id ).subscribe(async(res: any) => {
              console.log(res)
            if (res.success) {
              console.log(res)
              this.json = res.success.firequote;
               console.log(this.json)
               await this.getclient()
               await this.getAllProjects()
            }
            this.loaderService.hideLoader();
           },(err)=>{
            
            this.loaderService.hideLoader();
            this.alertService.presentNetworkAlert();
           });
        }catch(e){
          this.loaderService.hideLoader();
        }
     
      });
    }else {
    
      this.loadData();
    }
  }
  add() {
    this.router.navigate(['/fireQuote-add'])
  }

  details(data) {
    localStorage.setItem('fireQuoteDetails', JSON.stringify(data));
    this.router.navigate(['/fireQuote-details'])
  }


  getclient()
  {
    console.log("clients")
    return new Promise(resolve => {
      this.json.forEach(element => {
          this.httpService.postApi(element['client'], 'Client/getbyid/' + element['client']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.clients[element['client']] = res.success.client
               console.log(this.clients)
            }
          });
      })
      resolve(1)      
    })
  }


  getAllProjects() {
    
    return new Promise(resolve => {
      this.json.forEach(element => {
          this.httpService.postApi(element['project'], 'Project/getbyprojectProjectid/' + element['project']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.allProjects[element['project']] = res.success.project[0]
               console.log(this.allProjects)
            }
          });
      })
      resolve(1)      
    })
  }
}
