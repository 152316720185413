import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  constructor(private formBuilder:FormBuilder, private router:Router, 
    public alertService:AlertService,public util:UtilService,
   
    public loaderService:LoaderService,
     public httpService:HttpServiceService) { 

   

     }
  ngOnInit() {
  this.registerForm = this.formBuilder.group({
    userName: ['', [Validators.required]],
    password: ['', [Validators.required]],
    confirmpassword : ['',[]],
    mobileNumber: ['',[Validators.required]],
    emailID: ['',[Validators.required]]

  });
  }
  Register(value:any) {
    if (!this.registerForm.valid) {
			this.util.enableFromValidation(this.registerForm);
			return;
    }
    let localObj:any = {};
    localObj = value ? value: {}
    console.log(localObj);
    if(localObj.password !=""){
      if(localObj.password != localObj.confirmpassword) {
          this.alertService.presentAlert('Error',"Confirn Password Not Matched ! ","Try Again");
        return false
      }
      
    }
    // /client/client_registered?userName=karthik&password=1234&mobileNumber=12346&email=karthik@gmail.com
    this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
      this.httpService.postApi(localObj,`client/client_registered?userName=${localObj.userName}&password=${localObj.password}&mobileNumber=${localObj.mobileNumber}&email=${localObj.emailID}`).subscribe((res) => {
        // if(res.status){
          this.alertService.presentAlert('Success',`${res.message}`,"Success");
          console.log(this.registerForm)
          if(res.message)
          {
            this.router.navigate(['/login'])
          }
        //   // this.idOtpFieldVisible = true;
        //   this.loaderService.hideLoader();
          
  
        //   // localStorage.setItem(JSON.stringify('AlluserData',"res.status"))
        // } else {
        //   this.alertService.presentAlert('Error',`${res.message}`,"Try Again");
        // }
        
      });
    },err=>{
      this.loaderService.hideLoader();
      this.alertService.presentNetworkAlert();
    })
  
  }
}

