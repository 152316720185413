import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.scss']
})
export class ListCityComponent implements OnInit {

  json: any=[];
  cityList:any = []
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService,
    public loaderService: LoaderService) {

  }

  loadData(){
    this.cityList = []
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('/client/cities').subscribe((res: any) => {
          console.log(res)
          this.cityList = res.success ? res.success : []

          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }

  ngOnInit() {
    this.loadData();
  }
  add() {
    this.router.navigate(['/add-city'])
  }

  details(data) {
    localStorage.setItem('cityDetails', JSON.stringify(data));
    this.router.navigate(['/city-details'])
  }
}
