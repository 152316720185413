import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';


@Component({
  selector: 'app-home-list',
  templateUrl: './home-list.component.html',
  styleUrls: ['./home-list.component.scss']
})
export class HomeListComponent implements OnInit {
  json: any=[];
  cityList:any = [];
  addHomeForm:FormGroup;
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService,
    public loaderService: LoaderService,private formBuilder:FormBuilder) {

  }
  file:any;
  ngOnInit() {
    this.loadData();
    this.addHomeForm = this.formBuilder.group({
      mission: ['',[]],
      vision: ['', []],
      about_us: ["", []],
      noofemployee: ["",[]],
      nooflocations: ["",[]],
      satisfaction: ["",[]],
      happynumber: ["",[]],
      home_image: ["",[]]
	  
    });
  }

  loadData(){
    this.cityList = []
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('client/home_data_info').subscribe((res: any) => {
          let localResponse:any = {};
          localResponse = res.data ? res.data : {}
          this.cityList.push(localResponse)
          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }

  
  add() {
    this.router.navigate(['front-end/home/home-add'])
  }
  isViewListVisisble:boolean = true;
  isEditVisisble:boolean = false;
  homeListId:any = ""
  editHomeList(data:any){
    console.log(data)
    this.isViewListVisisble = false
    this.isEditVisisble = true ;
console.log(this.addHomeForm)
// this.addHomeForm.value.about_us = elem.about_us
this.addHomeForm.patchValue({
  about_us: data.aboutus ? data.aboutus : "",
  happynumber: data.happynumber ? data.happynumber : "",
  mission: data.mission ? data.mission : "",
  noofemployee: data.noofemployee ? data.noofemployee : "",
  nooflocations: data.nooflocations ? data.nooflocations : "",
  satisfaction: data.satisfaction ? data.satisfaction : "",
  vision: data.vision ? data.vision : ""
})
this.homeListId = ""
this.homeListId = data.id ? data.id: "";
console.log(this.homeListId)

  }

  onFileSelect(event) {

    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  submit(data){
    console.log(data)
    let sendData:any = {};
    sendData = {
      about_us: data.about_us ? data.about_us : "",
      happynumber: data.happynumber ? data.happynumber : "",
      mission: data.mission ? data.mission : "",
      noofemployee: data.noofemployee ? data.noofemployee : "",
      nooflocations: data.nooflocations ? data.nooflocations : "",
      satisfaction: data.satisfaction ? data.satisfaction : "",
      vision: data.vision ? data.vision : ""
    }

    const formData = new FormData();
        // formData.append('description', this.companyoverviewForm.get('description').value);
        formData.append('home_image', this.file);

    console.log(sendData)
   
  this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
    this.httpService.postApiForImageSending(formData,`/client/home_api_update?mission=${sendData.mission}&vision=${sendData.vision}&about_us=${sendData.about_us}&noofemployee=${sendData.noofemployee}&nooflocations=${sendData.nooflocations}&satisfaction=${sendData.satisfaction}&happynumber=${sendData.happynumber}&id=${this.homeListId}`).subscribe((res) => {
      console.log(res);
      if(res["success"]){
        this.alertService.presentAlert('Success','Successfully added','Okay');
        this.backToListList();
      }else {
        this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
      }
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })
    
 
}

backToListList() {
  this.isViewListVisisble = true;
  this.isEditVisisble = false;
  this.loadData();
}

delete(data) {
  this.loaderService.showLoader('Updating Please wait ..').then(()=>{
    try{
      this.httpService.postApi(data, '/client/delete_home_data?id='+data.id).subscribe((res: any) => {
        this.loaderService.hideLoader();
        if (res["success"]) {
          this.alertService.presentAlert('Success','Successfully deleted','Okay');
          this.backToListList();
          // this.router.navigate(['/our-team-list'])
        } else {
          this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
        }
      },(err)=>{
        
        this.loaderService.hideLoader();
        this.alertService.presentNetworkAlert();
       });    
    }catch(e){
      this.loaderService.hideLoader();
      this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
    }
  })

}


}
