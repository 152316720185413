import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-inbox-details',
  templateUrl: './inbox-details.component.html',
  styleUrls: ['./inbox-details.component.scss']
})
export class InboxDetailsComponent implements OnInit {
  serviceTypes:any=[]
inbox;
attachments:any=[]
list;
constructor( private router:Router,
  public httpService:HttpServiceService, public alertService:AlertService,
  public util:UtilService,
  public loaderService:LoaderService) {
  this.inbox = JSON.parse(localStorage.getItem('inboxDetails'))
  if(this.inbox.attachment !=null)
  {
    this.list =this.inbox.attachment;
    this.attachments=this.list.split(",");
  console.log(this.attachments)
  }
  console.log(this.inbox.servicetype)
 
  this.httpService.postApi(this.inbox.servicetype, 'Servicetype/getServiceById/' + this.inbox.servicetype).subscribe((res: any) => {
    if (res.success) {
     console.log(res)
      this.serviceTypes=res.success.servicetype;
    }
  });

}
clientData:any = {};
ngOnInit() {
  this.clientData =  JSON.parse(localStorage.getItem('AlluserData'))
  console.log(this.clientData)


}

toggleStatus(id,email,inboxmessage){
  let data = {
    email:email,
    inboxmessage:inboxmessage
  }
  this.loaderService.showLoader('Updating Please wait ..').then(()=>{
    try{  
      this.httpService.postApi( data, 'client/inbox-edit' + id).subscribe((res: any) => {
        this.loaderService.hideLoader();
        if (res["message"]) {
          this.alertService.presentAlert('message','Successfully updated','Okay');
          this.router.navigate(['inbox/inbox-list'])
        } else {
          this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
        }
      },(err)=>{
        
        this.loaderService.hideLoader();
        this.alertService.presentNetworkAlert();
       });    
    }catch(e){
      this.loaderService.hideLoader();
      this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
    }
  })
}

delete(data) {
  console.log(data)
  this.loaderService.showLoader('Updating Please wait ..').then(()=>{
    try{
      this.httpService.postApi(data, 'client/delete_inbox_list?id='+data.id).subscribe((res: any) => {
        this.loaderService.hideLoader();
        if (res["message"]) {
          this.alertService.presentAlert('message','Successfully deleted','Okay');
          this.router.navigate(['/inbox-list'])
        } else {
          this.alertService.presentAlert('Error',"Error Accord",'Okay');
        }
      },(err)=>{
        
        this.loaderService.hideLoader();
        this.alertService.presentNetworkAlert();
       });    
    }catch(e){
      this.loaderService.hideLoader();
      this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
    }
  })

}
edit(data) {
  console.log(data)
  localStorage.setItem('inboxEditData', JSON.stringify(data));
  this.router.navigate(['/inbox-edit'])
}

navigawAddIndex(){
  this.router.navigate(['inbox-add'])
}
}
