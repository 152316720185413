import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-company-overview-details',
  templateUrl: './company-overview-details.component.html',
  styleUrls: ['./company-overview-details.component.scss']
})
export class CompanyOverviewDetailsComponent implements OnInit {

  companyoverview:any = {};
  constructor(private router: Router,
    public httpService: HttpServiceService, public alertService: AlertService,
    public util: UtilService, public sanitizer: DomSanitizer,
    public loaderService: LoaderService) {
  }

  ngOnInit() {
    this.companyoverview = JSON.parse(localStorage.getItem('companyoverviewDetails'))
  }

  toggleStatus(id, image, description) {
    let data = {
      image: image,
      description: description
    }
    this.loaderService.showLoader('Updating Please wait ..').then(() => {
      try {
        this.httpService.postApi(data, 'client/update_company_overview' + id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success', 'Successfully updated', 'Okay');
            this.router.navigate(['/company-overview-list'])
          } else {
            this.alertService.presentAlert('Error', res["error"]["message"], 'Okay');
          }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })
  }

  delete(data) {
    this.loaderService.showLoader('Updating Please wait ..').then(() => {
      try {
        this.httpService.postApi(data,'client/delete_company_overview_list?id='+data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success', 'Successfully deleted', 'Okay');
            this.router.navigate(['/certificate-list'])
          } else {
            this.alertService.presentAlert('Error', res["error"]["message"], 'Okay');
          }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }
  edit(data) {
    console.log(data)
    localStorage.setItem('CompanyOverViewEdit', JSON.stringify(data));
    this.router.navigate(['/company-overview-edit'])
  }

}
