import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-cfe-quot-add',
  templateUrl: './cfe-quot-add.component.html',
  styleUrls: ['./cfe-quot-add.component.scss']
})
export class CfeQuotAddComponent implements OnInit {

  clients
  loading;
  cfeQuoteArray:any={}
  projectData;
  allProjects:any={};
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { }

  ngOnInit() {
    this.httpService.getApi('Client/getAllClients').subscribe((res: any) => {
      this.clients = res.success.client;
      console.log( this.clients)
     });
     this.getSignatureList();
  }


  submit(){

    console.log( this.cfeQuoteArray);
   // window.open(this.httpService.serverUrl+'TaxInvoice/test','_blank')
    this.cfeQuoteArray['createdAt']= new Date().getTime();
  this.cfeQuoteArray['isAvailable']= "1";
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{
        this.httpService.postApi(this.cfeQuoteArray, 'Cfequote/add').subscribe((res) => {
          this.loaderService.hideLoader();
          if(res["success"]){
            window.open(this.httpService.serverUrl+'Cfequote/test/' +res["success"],'_blank')
            this.alertService.presentAlert('Success','Successfully added','Okay');
            this.router.navigate(['/cfeQuote-list'])
          }else{
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
   }
   numberToWords(data)
   { 
     console.log(data);
     this.httpService.postApi(data,'taxInvoice/numberToWords/'  +data).subscribe((res: any) => {
       
        if(res)
        {
         console.log(res)
        this.cfeQuoteArray['amountInWords']=res;
        }
      
       });
   }

   signatureList:any = []
   getSignatureList() {
     this.signatureList = [];
     this.httpService.getApi('Client/signatures').subscribe((res: any) => {
       this.signatureList = res.success ? res.success : []
       console.log( this.clients)
      });
   }

   getProjects(data)
   {
     console.log(data);
    // this.httpService.postApi({client:data},'Project/getbyprojectbyClientId/' +data ).subscribe((res: any) => {
       this.httpService.postApi({client:data},'Client/getProjectFromProjectstatus/' +data ).subscribe(async(res: any) => {
       console.log(res)
       this.projectData = res.success.projects;
        console.log(this.projectData);
   await this.getAllProjects();
    });
   }
 
 
   getAllProjects() {
     
     return new Promise(resolve => {
       this.projectData.forEach(element => {
           this.httpService.postApi(element['project'], 'Project/getbyprojectProjectid/' + element['project']).subscribe((res: any) => {
             if (res.success) {
               console.log(res)
                this.allProjects[element['project']] = res.success.project[0]
                console.log(this.allProjects)
             }
           });
       })
       resolve(1)      
     })
   }
}
