import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-client-project-status',
  templateUrl: './client-project-status.component.html',
  styleUrls: ['./client-project-status.component.scss']
})
export class ClientProjectStatusComponent implements OnInit {

  clients: any = {}
  json: any=[];
  user:any={}
  projecteArray:any={}
  statuses:any=[]
  projectData;
  allProjects:any={};
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService,
    public loaderService: LoaderService) {
      this.user = JSON.parse(localStorage.getItem('AlluserData'))
    console.log(this.user)

  }

  
  
  ngOnInit() {
   // this.clientData =JSON.parse(localStorage.getItem('AlluserData'))
  // this.httpService.postApi({client:this.user.id},'Project/getbyprojectbyClientId/' +this.user.id ).subscribe((res: any) => {
    this.httpService.postApi({client:this.user.id},'Client/getProjectFromProjectstatus/' +this.user.id ).subscribe(async(res: any) => {
  console.log(res)
   // this.json = res.success.projects;
   this.projectData = res.success.projects;
   console.log(this.projectData);
await this.getAllProjects();
 
 });
      
    }


    getprojectStatus(data)
    {
    console.log(data)
    this.httpService.postApi({project_id:data},'Client/getProjectStatus/' +data ).subscribe((res: any) => {
      console.log(res)
      this.statuses = res.success.status;
      
   
   });
    }
   
    getAllProjects() {
    
      return new Promise(resolve => {
        this.projectData.forEach(element => {
            this.httpService.postApi(element['project'], 'Project/getbyprojectProjectid/' + element['project']).subscribe((res: any) => {
              if (res.success) {
                console.log(res)
                 this.allProjects[element['project']] = res.success.project[0]
                 console.log(this.allProjects)
              }
            });
        })
        resolve(1)      
      })
    }

  }


 





