import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-conversation-report',
  templateUrl: './conversation-report.component.html',
  styleUrls: ['./conversation-report.component.scss']
})
export class ConversationReportComponent implements OnInit {
  statuses:any=[]
  clients: any = {}
  json: any=[];
  projects:any=[]
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService,
    public loaderService: LoaderService) {

  }

  async loadData(){
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('Client/getAllConversations').subscribe(async(res: any) => {
          if (res.success) {
            console.log(res)
            this.json = res.success.conversations;
             console.log(this.json)
             await this.getclient();
             await this.getstatus();
             await this. getProject();
          }
         
          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }

  async ngOnInit() {
    this.loadData();
  }


  getclient()
  {
   
    return new Promise(resolve => {
      this.json.forEach(element => {
          this.httpService.postApi(element['client_id'], 'Client/getbyid/' + element['client_id']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.clients[element['client_id']] = res.success.client
               console.log(this.clients)
            }
          });
      })
      resolve(1)      
    })
  }

  getstatus()
  {
    
    return new Promise(resolve => {
    
      this.json.forEach(element => {
        element['status_name']=  element['status_name']==''?0:element['status_name'];
          this.httpService.postApi(element['status_name'], 'Status/getStatusBystatusid/' + element['status_name']).subscribe((res: any) => {
            if (res.success) {
             console.log(res)
               //this.projectStatus[element['status']] = res.success
              // console.log(this.projectStatus)
              this.statuses[element['status_name']]=res.success;
            }
          });
        
      })
      resolve(1) 
        
    })
  }
 // this.httpService.postApi(data,'Project/getprojectById/' +data).subscribe((res: any) => {
    //   this.projects = res.success;
    //   console.log(this.projects)
    //  });

  getProject()
  {
    return new Promise(resolve => {
    
      this.json.forEach(element => {
        element['project_id']=  element['project_id']==''?0:element['project_id'];
          this.httpService.postApi(element['project_id'], 'Project/getbyprojectProjectid/' + element['project_id']).subscribe((res: any) => {
            if (res.success) {
             console.log(res)
               //this.projectStatus[element['status']] = res.success
              // console.log(this.projectStatus)
              this.projects[element['project_id']]=res.success.project[0];
            }
          });
        
      })
      resolve(1) 
        
    })
  }
  // add() {
  //   this.router.navigate(['/project-add'])
  // }

  // details(data) {
  //   localStorage.setItem('projectDetails', JSON.stringify(data));
  //   this.router.navigate(['/project-details'])
  // }
}
