import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {

    serverUrl = 'https://knacktech.lebakatech.com/backend/index.php/';
     // serverUrl = 'https://localhost:4200/backend/index.php/';
  // serverUrl = 'http://knacktech.lebakatech.com/knacktech-backend/index.php/';
    imageUrl = 'https://knacktech.lebakatech.com/backend/uploads/';
    
   //imageUrl = 'http://knacktech.lebakatech.com/knacktech-backend/uploads/';

   httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
  };
  httpOptionsForImage = {
    headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
  };
  clientData:any =JSON.parse(localStorage.getItem('AlluserData'));
  constructor(private http: HttpClient) { 
    console.log(this.clientData);
    // this.clientData =  JSON.parse(localStorage.getItem('AlluserData'))
    // // this.isAdmin()
    // console.log(this.clientData)
    // console.log(this.isAdmin())

  }

  getApi(url: any) {
    return this.http.get<any[]>(this.serverUrl + url).pipe(
        catchError(this.handleError)
    );
  }

  postApi(json: any, url) {
    return this.http.post<any>(this.serverUrl + url, json, this.httpOptions).pipe(
        catchError(this.handleError)
    );
  }
  postApiForImageSending(json: any, url) {
    return this.http.post<any>(this.serverUrl + url, json).pipe(
        catchError(this.handleError)
    );
  }
  putApi(json: any, url) {
    return this.http.put<any>(this.serverUrl + url, json, this.httpOptions).pipe(
        catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  status = "0";
  isAdmin(){
   return this.clientData.isAdmin == "1" ? true : false
  }


}
