import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-status-add',
  templateUrl: './status-add.component.html',
  styleUrls: ['./status-add.component.css']
})
export class StatusAddComponent implements OnInit {

  statusForm: FormGroup;
  loading;
  serviceTypes
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { }

  ngOnInit() {
    this.statusForm = this.formBuilder.group({
                serviceType: ['', [Validators.required]],
                status: ['', [Validators.required]],
                statusno: ['', [Validators.required]],
               // isAvailable: ['1', [Validators.required]],
            });

            this.httpService.getApi('Servicetype/getAllServices').subscribe((res: any) => {
              this.serviceTypes = res.success.servicetype;
              
             });
   
  }


  submit(data){
    data['createdAt']= new Date().getTime();
    data['isAvailable']= 1;
    if (!this.statusForm.valid) {
			this.util.enableFromValidation(this.statusForm);
			return;
    }
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Status/add').subscribe((res) => {
          this.loaderService.hideLoader();
          if(res["success"]){
            this.alertService.presentAlert('Success','Successfully added','Okay');
            this.router.navigate(['/status-list'])
          }else{
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }

}
