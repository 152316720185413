import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

//import { ConsoleReporter } from 'jasmine';

@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.css']
})
export class ProjectAddComponent implements OnInit {

  projectForm: FormGroup;
  loading;
  serviceTypes;
  cities=[{name:'Vijayawada',id:1}]
  clients
  status
  projectData
  allProjects:any={};
  existingprojectData
  nameopens=false;
  iscalled:boolean=false;
  json:any=[];
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { }

  ngOnInit() {
    this.projectForm = this.formBuilder.group({
                name: ['', [Validators.required]],
                client: ['', [Validators.required]],
                city: ['', [Validators.required]],
                serviceType: ['', [Validators.required]],
               // isAvailable: ['', [Validators.required]],
                projectAddress: ['', [Validators.required]],
                siteArea: ['', [Validators.required]],
                status: ['-', [Validators.required]],
                date: ['', [Validators.required]],
                mail: ['',[]],
               // name1:['',[]]
            });
           // this.loaderService.showLoader('');

            this.httpService.getApi('Servicetype/getAllServices').subscribe((res: any) => {
              this.serviceTypes = res.success.servicetype;
              console.log( this.serviceTypes)
              
             });
             this.httpService.getApi('Client/getAllClients').subscribe((res: any) => {
              this.clients = res.success.client;
              console.log( this.clients)
             });

             this.getCitylist();
  }

  serviceTypeOnChange()
  {
    this.status = []
    let data=this.projectForm.value.serviceType;
    console.log(data)
    this.httpService.postApi(data,'Status/getstatusById/' +data).subscribe((res: any) => {
      this.status = res.success;
  
      console.log(this.status)
     });
  }

  

  submit(data){
    console.log(data)
    data['createdAt']= new Date().getTime();
     data['isAvailable']=1;
   
    if (!this.projectForm.valid) {
			this.util.enableFromValidation(this.projectForm);
			return;
    }

//get all projects  if (res.success) {
   // console.log(res)
   // this.json = res.success.project;
   //  console.log(this.json)
   
 // }
this.httpService.getApi('Project/getAllProjects').subscribe(async(res: any) => {
  let val = res.success.project;
  var count=0;
for (let i = 0; i < val.length; i++) {

if((val[i]['name']==this.projectForm.value.name)  &&  (val[i]['serviceType']==this.projectForm.value.serviceType))
{

 this.alertService.presentAlert('Success', 'Service Type already exists for that project', 'Okay');
 console.log("matched");
 count=1;
break;
}

}

if(count==0)
{
  this.loaderService.showLoader('Adding Please wait ..').then(()=>{
    try{
      this.httpService.postApi(data, 'Project/add').subscribe((res) => {
        this.loaderService.hideLoader();
        if(res["success"]){
          console.log(res.success)
         // this.sendStatusClient();
         //this.addProjectservice();
         if(this.iscalled==true)
         {
          this.addProjectservice(res.success);
         }
   
          this.alertService.presentAlert('Success','Successfully added','Okay');
         
          this.router.navigate(['/project-list'])
        }else{
          this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
        }    
      },(err)=>{
        
        this.loaderService.hideLoader();
        this.alertService.presentNetworkAlert();
       });
    }catch(e){
      this.loaderService.hideLoader();
      this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
    }
  })

}



 });

      
    
  }
  cityList:any = []
  getCitylist(){
    this.cityList = []
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('/client/cities').subscribe((res: any) => {
          console.log(res)
          this.cityList = res.success ? res.success : []

          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }
  clientPhoneNumber:any = ""
  getClientNumber() {
    let clientId = "";
    this.clientPhoneNumber = "";
   clientId =  this.projectForm.value.client;
   this.clientPhoneNumber = this.clients ? this.clients.filter((v) => v.id == clientId )[0].mobileNumber : "";
   console.log(this.clientPhoneNumber)

  
   //get project list
   this.httpService.postApi({client:this.projectForm.value.client},'Client/getProjectFromProjectstatus/' +this.projectForm.value.client ).subscribe(async(res: any) => {
    console.log(res)
    this.projectData = res.success.projects;
   await this.getAllProjects();
     console.log(this.projectData);
 
 });
  }
  getAllProjects() {
    
    return new Promise(resolve => {
      this.projectData.forEach(element => {
          this.httpService.postApi(element['project'], 'Project/getbyprojectProjectid/' + element['project']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.allProjects[element['project']] = res.success.project[0]
               console.log(this.allProjects)
            }
          });
      })
      resolve(1)      
    })
  }

 getprojectName()
{
  this.httpService.postApi({project:this.projectForm.value.client},'Project/getbyprojectbyClientId/' +this.projectForm.value.client).subscribe((res: any) => {
    console.log(res)
    this.existingprojectData = res.success.projects[0];
     console.log(this.existingprojectData)
 
 });
}

  statusName:any = "";
  getStatusName(){
    this.statusName = "";
    let status_id : any = "";
    status_id =  this.projectForm.value.status;
    this.statusName =  this.status ?  this.status.filter((v) => v.id ==  status_id)[0].id : "";
    console.log( this.statusName)


  }
  locationName:any = "";
  getCityNameOnChange(){
    this.locationName = "";
    let location_id:any = "";
    location_id = this.projectForm.value.city
    this.locationName = this.cityList ? this.cityList.filter(d=> d.id == location_id)[0].city_name : "";
    console.log(this.locationName)
  }

  sendStatusClient(){
    let obj = {
      mobileNumber : this.clientPhoneNumber ? this.clientPhoneNumber : "",
      message : this.statusName ? this.statusName : ""
    }
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{
        this.httpService.postApi(obj, `/Client/status_api?service_type=${this.projectForm.value.serviceType}&mobile_number=${this.clientPhoneNumber}&status_position=${this.statusName}&location=${this.locationName}&client_id=${this.projectForm.value.client}&project_id=${this.projectForm.value.id}&status_name=${this.projectForm.value.status}`).subscribe((res) => {
          this.loaderService.hideLoader();
          // if(res["success"]){
          //   this.alertService.presentAlert('Success','Successfully added','Okay');
          //   // this.router.navigate(['/project-list'])
          // }else{
          //   this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          // }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }

  addProjectservice(x)
  {

    let data={
      client:this.projectForm.value.client,
      project:x
    }
    this.httpService.postApi(data, 'Client/projectServiceadd').subscribe((res) => {
    
      if(res["success"]){
  
        this.alertService.presentAlert('Success','Successfully added','Okay');
       
    
      }   
    });
  }

  getProperty()
  {

if(this.projectForm.value.name=="newProject")
{
 
  this.iscalled=true;
 

   
   this.nameopens=true;
   this.projectForm.value.name=""

    this.projectForm.value.projectAddress=""
    this.projectForm.value.siteArea=""
    this.projectForm.value.city=""

 

}
else
{
  console.log(this.projectForm.value.client)
  this.iscalled=false;
  this.nameopens=false;
  this.httpService.postApi({client:this.projectForm.value.client},'Project/getbyprojectbyClientId/' +this.projectForm.value.client).subscribe((res: any) => {
    console.log(res)
    this.existingprojectData = res.success.projects[0];
     console.log(this.existingprojectData)
     //this.projectForm.value.name=this.projectForm.value.name1;
 //this.projectForm.value.projectAddress=res.success.projects[0].projectAddress
 this.projectForm.patchValue({'projectAddress': res.success.projects[0].projectAddress});
 this.projectForm.patchValue({'siteArea': res.success.projects[0].siteArea});
 this.projectForm.patchValue({'city': res.success.projects[0].city});
 console.log(this.projectForm.value)
 });
}
  }


  
}

