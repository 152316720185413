import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-certificate-list',
  templateUrl: './certificate-list.component.html',
  styleUrls: ['./certificate-list.component.css']
})
export class CertificateListComponent implements OnInit {
clients: any = {}
  json: any=[];
  services:any={}
  allProjects:any={}
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService, public sanitizer: DomSanitizer,
    public loaderService: LoaderService) {

  }

  async loadData(){
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('KCertificate/getAllCertificates').subscribe(async(res: any) => {
          console.log(res.success.certificate)
          
          if (res.success) {
            console.log(res)
            this.json = res.success.certificate;
             console.log(this.json)
             await this.getclient()
             await this.getServices()
             await this.getAllProjects()
          }
          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }

  async ngOnInit() {
    this.loadData();
  }
  add() {
    this.router.navigate(['/certificate-add'])
  }

  details(data) {
    localStorage.setItem('certificateDetails', JSON.stringify(data));
    this.router.navigate(['/certificate-details'])
  }


  getclient()
  {
    console.log("clients")
    return new Promise(resolve => {
      this.json.forEach(element => {
          this.httpService.postApi(element['client'], 'Client/getbyid/' + element['client']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.clients[element['client']] = res.success.client
               console.log(this.clients)
            }
          });
      })
      resolve(1)      
    })
  }



  getServices() {
    console.log("services")
    return new Promise(resolve => {
      this.json.forEach(element => {
          this.httpService.postApi(element['serviceType'], 'Servicetype/getServiceById/' + element['serviceType']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.services[element['serviceType']] = res.success.servicetype
               console.log(this.services)
            }
          });
      })
      resolve(1)      
    })
  }

  getAllProjects() {
    
    return new Promise(resolve => {
      this.json.forEach(element => {
          this.httpService.postApi(element['project'], 'Project/getbyprojectProjectid/' + element['project']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.allProjects[element['project']] = res.success.project[0]
               console.log(this.allProjects)
            }
          });
      })
      resolve(1)      
    })
  }
}
