import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
@Component({
  selector: 'app-home-add',
  templateUrl: './home-add.component.html',
  styleUrls: ['./home-add.component.scss']
})
export class HomeAddComponent implements OnInit {
  addHomeForm:FormGroup;

  constructor(private formBuilder:FormBuilder, private router:Router, 
    public alertService:AlertService,
   
    public loaderService:LoaderService,
     public httpService:HttpServiceService) { }
     file:any;
  ngOnInit() {
    this.addHomeForm = this.formBuilder.group({
      mission: ['',[]],
      vision: ['', []],
      about_us: ["", []],
      noofemployee: ["",[]],
      nooflocations: ["",[]],
      satisfaction: ["",[]],
      happynumber: ["",[]],
      home_image: ["",[]]
	  
    });
  }

  submit(data){
    let sendData:any = {};
    const formData = new FormData();
        // formData.append('description', this.companyoverviewForm.get('description').value);
        formData.append('home_image', this.file);
    sendData = {
      about_us: data.about_us ? data.about_us : "",
      happynumber: data.happynumber ? data.happynumber : "",
      mission: data.mission ? data.mission : "",
      noofemployee: data.noofemployee ? data.noofemployee : "",
      nooflocations: data.nooflocations ? data.nooflocations : "",
      satisfaction: data.satisfaction ? data.satisfaction : "",
      vision: data.vision ? data.vision : ""
    }
    console.log(sendData)
   
  this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
    this.httpService.postApiForImageSending(formData,`/client/home_api_update?mission=${sendData.mission}&vision=${sendData.vision}&about_us=${sendData.about_us}&noofemployee=${sendData.noofemployee}&nooflocations=${sendData.nooflocations}&satisfaction=${sendData.satisfaction}&happynumber=${sendData.happynumber}&id=`).subscribe((res) => {
      console.log(res);
      if(res["success"]){
        this.alertService.presentAlert('Success','Successfully added','Okay');
        this.router.navigate(['/front-end/home/home-list'])
      }else {
        this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
      }
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })
    
 
}


onFileSelect(event) {

  if (event.target.files.length > 0) {
    this.file = event.target.files[0];
  }
}

}
