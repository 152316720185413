import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientAddComponent } from './pages/client/client-add/client-add.component';
import { ClientEditComponent } from './pages/client/client-edit/client-edit.component';
import { ClientListComponent } from './pages/client/client-list/client-list.component';
import { ClientDetailsComponent } from './pages/client/client-details/client-details.component';
import { ServiceTypeAddComponent } from './pages/serviceType/service-type-add/service-type-add.component';
import { ServiceTypeListComponent } from './pages/serviceType/service-type-list/service-type-list.component';
import { ServiceTypeDetailsComponent } from './pages/serviceType/service-type-details/service-type-details.component';
import { ServiceTypeEditComponent } from './pages/serviceType/service-type-edit/service-type-edit.component';
import { ProjectEditComponent } from './pages/project/project-edit/project-edit.component';
import { ProjectAddComponent } from './pages/project/project-add/project-add.component';
import { ProjectDetailsComponent } from './pages/project/project-details/project-details.component';
import { ProjectListComponent } from './pages/project/project-list/project-list.component';
import { CertificateListComponent } from './pages/certificate/certificate-list/certificate-list.component';
import { CertificateDetailsComponent } from './pages/certificate/certificate-details/certificate-details.component';
import { CertificateEditComponent } from './pages/certificate/certificate-edit/certificate-edit.component';
import { CertificateAddComponent } from './pages/certificate/certificate-add/certificate-add.component';
import { StatusListComponent } from './pages/status/status-list/status-list.component';
import { StatusDetailsComponent } from './pages/status/status-details/status-details.component';
import { StatusEditComponent } from './pages/status/status-edit/status-edit.component';
import { StatusAddComponent } from './pages/status/status-add/status-add.component';
import { EcQuoteListComponent } from './pages/ecQuote/ec-quote-list/ec-quote-list.component';
import { EcQuoteDetailsComponent } from './pages/ecQuote/ec-quote-details/ec-quote-details.component';
import { EcQuoteEditComponent } from './pages/ecQuote/ec-quote-edit/ec-quote-edit.component';
import { EcQuoteAddComponent } from './pages/ecQuote/ec-quote-add/ec-quote-add.component';
import { AaiQuoteListComponent } from './pages/aaiQuote/aai-quote-list/aai-quote-list.component';
import { AaiQuoteDetailsComponent } from './pages/aaiQuote/aai-quote-details/aai-quote-details.component';
import { AaiQuoteEditComponent } from './pages/aaiQuote/aai-quote-edit/aai-quote-edit.component';
import { AaiQuoteAddComponent } from './pages/aaiQuote/aai-quote-add/aai-quote-add.component';
import { FireQuoteListComponent } from './pages/fireQuote/fire-quote-list/fire-quote-list.component';
import { FireQuoteDetailsComponent } from './pages/fireQuote/fire-quote-details/fire-quote-details.component';
import { FireQuoteEditComponent } from './pages/fireQuote/fire-quote-edit/fire-quote-edit.component';
import { FireQuoteAddComponent } from './pages/fireQuote/fire-quote-add/fire-quote-add.component';
import { TaxInvoiceAddComponent } from './pages/taxInvoice/tax-invoice-add/tax-invoice-add.component';
import { TaxInvoiceEditComponent } from './pages/taxInvoice/tax-invoice-edit/tax-invoice-edit.component';
import { TaxInvoiceDetailsComponent } from './pages/taxInvoice/tax-invoice-details/tax-invoice-details.component';
import { TaxInvoiceListComponent } from './pages/taxInvoice/tax-invoice-list/tax-invoice-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CertificateTypeListComponent } from './pages/certificateType/certificate-type-list/certificate-type-list.component';
import { CertificateTypeDetailsComponent } from './pages/certificateType/certificate-type-details/certificate-type-details.component';
import { CertificateTypeEditComponent } from './pages/certificateType/certificate-type-edit/certificate-type-edit.component';
import { CertificateTypeAddComponent } from './pages/certificateType/certificate-type-add/certificate-type-add.component';
import { LoginComponent } from './pages/login/login.component';
import { CertificateListsComponent } from './pages/certificate-lists/certificate-lists.component';
import { CfoQuoteAddComponent } from './pages/cfoQuote/cfo-quote-add/cfo-quote-add.component';
import { CfoQuoteEditComponent } from './pages/cfoQuote/cfo-quote-edit/cfo-quote-edit.component';
import { CfoQuoteListComponent } from './pages/cfoQuote/cfo-quote-list/cfo-quote-list.component';
import { CfeQuotAddComponent } from './pages/cfeQuote/cfe-quot-add/cfe-quot-add.component';
import { CfeQuotDetailsComponent } from './pages/cfeQuote/cfe-quot-details/cfe-quot-details.component';
import { CfeQuotEditComponent } from './pages/cfeQuote/cfe-quot-edit/cfe-quot-edit.component';
import { CfeQuotListComponent } from './pages/cfeQuote/cfe-quot-list/cfe-quot-list.component';
import { CfoQuoteDetailsComponent } from './pages/cfoQuote/cfo-quote-details/cfo-quote-details.component';
import { ReraQuoteAddComponent } from './pages/reraQuote/rera-quote-add/rera-quote-add.component';
import { ReraQuoteEditComponent } from './pages/reraQuote/rera-quote-edit/rera-quote-edit.component';
import { ReraQuoteDetailsComponent } from './pages/reraQuote/rera-quote-details/rera-quote-details.component';
import { ReraQuoteListComponent } from './pages/reraQuote/rera-quote-list/rera-quote-list.component';
import { ClientStatusReportListComponent } from './pages/clientStatusReport/client-status-report-list/client-status-report-list.component';
import { UpdateProfileComponent } from './pages/profile/update-profile/update-profile.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { HomeComponent } from './pages/front-end/home/home.component';
import { ContactComponent } from './pages/front-end/contact/contact.component';
import { ObjectiveAddComponent } from './pages/front-end/objectives/objective-add/objective-add.component';
import { ObjectiveEditComponent } from './pages/front-end/objectives/objective-edit/objective-edit.component';
import { ObjectiveListComponent } from './pages/front-end/objectives/objective-list/objective-list.component';
import { ObjectiveDetailsComponent } from './pages/front-end/objectives/objective-details/objective-details.component';
import { AddCityComponent } from './pages/city/add-city/add-city.component';
import { RegisterComponent } from './pages/register/register.component';
import { ListCityComponent } from './pages/city/city-list/city-list.component';
import { DetailsCityComponent } from './pages/city/city-details/city-details.component';
import { EditCityComponent } from './pages/city/city-edit/city-edit.component';
//import { HomeEditComponent } from './pages/front-end/home/home-edit/home-edit.component';
import { HomeAddComponent } from './pages/front-end/home/home-add/home-add.component';
import { HomeEditComponent } from './pages/front-end/home/home-edit/home-edit.component';
import { HomeListComponent } from './pages/front-end/home/home-list/home-list.component';
import { HomeDetailsComponent } from './pages/front-end/home/home-details/home-details.component';
import { FeedbackAddComponent } from './pages/front-end/feedback/feedback-add/feedback-add.component';
import { FeedbackEditComponent } from './pages/front-end/feedback/feedback-edit/feedback-edit.component';
import { FeedbackListComponent } from './pages/front-end/feedback/feedback-list/feedback-list.component';
import { FeedbackDetailsComponent } from './pages/front-end/feedback/feedback-details/feedback-details.component';
import { ContactEditComponent } from './pages/front-end/contact/contact-edit/contact-edit.component';
import { InboxAddComponent } from './pages/inbox/inbox-add/inbox-add.component';
import { InboxEditComponent } from './pages/inbox/inbox-edit/inbox-edit.component';
import { InboxListComponent } from './pages/inbox/inbox-list/inbox-list.component';
import { InboxDetailsComponent } from './pages/inbox/inbox-details/inbox-details.component';
import { SignatureAddComponent } from './pages/signature/signature-add/signature-add.component';
import { SignatureEditComponent } from './pages/signature/signature-edit/signature-edit.component';
import { SignatureListComponent } from './pages/signature/signature-list/signature-list.component';
import { SignatureDetailsComponent } from './pages/signature/signature-details/signature-details.component';
import { ServiceAddComponent } from './pages/front-end/services/service-add/service-add.component';
import { ServiceEditComponent } from './pages/front-end/services/service-edit/service-edit.component';
import { ServiceListComponent } from './pages/front-end/services/service-list/service-list.component';
import { ServiceDetailsComponent } from './pages/front-end/services/service-details/service-details.component';
import { OurTeamAddComponent } from './pages/front-end/our-teams/our-team-add/our-team-add.component';
import { OurTeamEditComponent } from './pages/front-end/our-teams/our-team-edit/our-team-edit.component';
import { OurTeamListComponent } from './pages/front-end/our-teams/our-team-list/our-team-list.component';
import { OurTeamDetailsComponent } from './pages/front-end/our-teams/our-team-details/our-team-details.component';
import { OurTeamDirectorAddComponent } from './pages/front-end/our-team-director/our-team-director-add/our-team-director-add.component';
import { OurTeamDirectorEditComponent } from './pages/front-end/our-team-director/our-team-director-edit/our-team-director-edit.component';
import { OurTeamDirectorDetailsComponent } from './pages/front-end/our-team-director/our-team-director-details/our-team-director-details.component';
import { OurTeamDirectorListComponent } from './pages/front-end/our-team-director/our-team-director-list/our-team-director-list.component';
import { CompanyOverviewAddComponent } from './pages/front-end/company-overview/company-overview-add/company-overview-add.component';
import { CompanyOverviewEditComponent } from './pages/front-end/company-overview/company-overview-edit/company-overview-edit.component';
import { CompanyOverviewDetailsComponent } from './pages/front-end/company-overview/company-overview-details/company-overview-details.component';
import { CompanyOverviewListComponent } from './pages/front-end/company-overview/company-overview-list/company-overview-list.component';
import { ContactAddComponent } from './pages/front-end/contact/contact-add/contact-add.component';
import { ContactListComponent } from './pages/front-end/contact/contact-list/contact-list.component';
import { GeneralListComponent } from './pages/general/general-list/general-list.component';
import{GeneralViewComponent }  from './pages/general/general-view/general-view.component';
import { ClientListAddComponent } from './pages/front-end/client-list/client-list-add/client-list-add.component';
import { ClientListDataComponent } from './pages/front-end/client-list/client-list-data/client-list-data.component';
import { ClientListDetailsComponent } from './pages/front-end/client-list/client-list-details/client-list-details.component';
import { ClientListEditComponent } from './pages/front-end/client-list/client-list-edit/client-list-edit.component';
import { ClientStatusListComponent } from './pages/client-status-list/client-status-list.component';
import { SentMailComponent } from './pages/inbox/sent-mail/sent-mail.component';
import { ReceivedMailComponent } from './pages/inbox/received-mail/received-mail.component';
import { ClientProjectStatusComponent } from './pages/client-project-status/client-project-status.component';
import { ConversationReportComponent } from './pages/conversation-report/conversation-report.component';
import { ClientPartnersAddComponent } from './pages/clientPartners/client-partners-add/client-partners-add.component';
import { ClientPartnersListComponent } from './pages/clientPartners/client-partners-list/client-partners-list.component';
import { ClientPartnersDetailsComponent } from './pages/clientPartners/client-partners-details/client-partners-details.component';
import { ClientPartnersEditComponent } from './pages/clientPartners/client-partners-edit/client-partners-edit.component';
import { FireOccupencyAddComponent } from './pages/fireOccupencyQuote/fire-occupency-add/fire-occupency-add.component';
import { FireOccupencyDetailsComponent } from './pages/fireOccupencyQuote/fire-occupency-details/fire-occupency-details.component';
import { FireOccupencyEditComponent } from './pages/fireOccupencyQuote/fire-occupency-edit/fire-occupency-edit.component';
import { FireRenewalAddComponent } from './pages/fireRenewalQuote/fire-renewal-add/fire-renewal-add.component';
import { FireRenewalEditComponent } from './pages/fireRenewalQuote/fire-renewal-edit/fire-renewal-edit.component';
import { FireRenewalDetailsComponent } from './pages/fireRenewalQuote/fire-renewal-details/fire-renewal-details.component';
import { FireRenewalListComponent } from './pages/fireRenewalQuote/fire-renewal-list/fire-renewal-list.component';
import { FireOccupencyListComponent } from './pages/fireOccupencyQuote/fire-occupency-list/fire-occupency-list.component';
const routes: Routes = [
  {
    path:'',
    component:LoginComponent
  }, {
    path:'dashboard',
    component:DashboardComponent
  },
  {
    path: 'client-add',
    component: ClientAddComponent
  },
  {
    path: 'client-edit',
    component: ClientEditComponent
  },
  {
    path: 'client-list',
    component: ClientListComponent
  },
  {
    path: 'client-details',
    component: ClientDetailsComponent
  },
  {
    path: 'service-type-add',
    component: ServiceTypeAddComponent
  },
  {
    path: 'service-type-edit',
    component: ServiceTypeEditComponent
  },
  {
    path: 'service-type-details',
    component: ServiceTypeDetailsComponent
  },
  {
    path: 'service-type-list',
    component: ServiceTypeListComponent
  },
  {
    path: 'project-add', 
    component: ProjectAddComponent
  },
  {
    path: 'project-edit',
    component: ProjectEditComponent
  },
  {
    path: 'project-details',
    component: ProjectDetailsComponent
  },
  {
    path: 'project-list',
    component: ProjectListComponent
  },
  {
    path: 'certificate-add',
    component: CertificateAddComponent
  },
  {
    path: 'certificate-edit',
    component: CertificateEditComponent
  },
  {
    path: 'certificate-details',
    component: CertificateDetailsComponent
  },
  {
    path: 'certificate-list',
    component: CertificateListComponent
  },
  {
    path: 'status-add',
    component: StatusAddComponent
  },
  {
    path: 'status-edit',
    component: StatusEditComponent
  },
  {
    path: 'status-details',
    component: StatusDetailsComponent
  },
  {
    path: 'status-list',
    component: StatusListComponent
  },
  {
    path: 'ecQuote-add',
    component: EcQuoteAddComponent
  },
  {
    path: 'ecQuote-edit',
    component: EcQuoteEditComponent
  },
  {
    path: 'ecQuote-details',
    component: EcQuoteDetailsComponent
  },
  {
    path: 'ecQuote-list',
    component: EcQuoteListComponent
  },
  {
    path: 'fireQuote-add',
    component: FireQuoteAddComponent
  },
  {
    path: 'fireQuote-edit',
    component: FireQuoteEditComponent
  },
  {
    path: 'fireQuote-details',
    component: FireQuoteDetailsComponent
  },
  {
    path: 'fireQuote-list',
    component: FireQuoteListComponent
  },
  {
    path: 'aaiQuote-add',
    component: AaiQuoteAddComponent
  },
  {
    path: 'aaiQuote-edit',
    component: AaiQuoteEditComponent
  },
  {
    path: 'aaiQuote-details',
    component: AaiQuoteDetailsComponent
  },
  {
    path: 'aaiQuote-list',
    component: AaiQuoteListComponent
  },
  {
    path: 'taxInvoice-add',
    component: TaxInvoiceAddComponent
  },
  {
    path: 'taxInvoice-edit',
    component: TaxInvoiceEditComponent
  },
  {
    path: 'taxInvoice-details',
    component: TaxInvoiceDetailsComponent
  },
  {
    path: 'taxInvoice-list',
    component: TaxInvoiceListComponent
  },
  {
    path: 'certificateType-add',
    component: CertificateTypeAddComponent
  },
  {
    path: 'certificateType-edit',
    component: CertificateTypeEditComponent
  },
  {
    path: 'certificateType-details',
    component: CertificateTypeDetailsComponent
  },
  {
    path: 'certificateType-list',
    component: CertificateTypeListComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'ceritifaceLists',
    component: CertificateListsComponent
  },
  {
    path: 'cfoQuote-add',
    component: CfoQuoteAddComponent
  },
  {
    path: 'cfoQuote-edit',
    component: CfoQuoteEditComponent
  },
  {
    path: 'cfoQuote-details',
    component: CfoQuoteDetailsComponent
  },
  {
    path: 'cfoQuote-list',
    component: CfoQuoteListComponent
  },
  {
    path: 'cfeQuote-add',
    component: CfeQuotAddComponent
  },
  {
    path: 'cfeQuote-edit',
    component: CfeQuotEditComponent
  },
  {
    path: 'cfeQuote-details',
    component: CfeQuotDetailsComponent
  },
  {
    path: 'cfeQuote-list',
    component: CfeQuotListComponent
  },
  {
    path: 'reraQuote-add',
    component: ReraQuoteAddComponent
  },
  {
    path: 'reraQuote-edit',
    component: ReraQuoteEditComponent
  },
  {
    path: 'reraQuote-details',
    component: ReraQuoteDetailsComponent
  },
  {
    path: 'reraQuote-list',
    component: ReraQuoteListComponent
  },
  {
    path: 'clientStatusReport',
    component: ClientStatusReportListComponent
  },
  {
    path: 'client-profile',
    component: UpdateProfileComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },

  {
    path: 'front-end/home',
    component: HomeComponent
  },
  {
    path: 'front-end/contact',
    component: ContactComponent
  },
  {
    path: 'front-end/objectives/objective-add',
    component: ObjectiveAddComponent
  },
  {
    path: 'front-end/objectives/objective-edit',
    component: ObjectiveEditComponent
  },
  {
    path: 'front-end/objectives/objective-list',
    component: ObjectiveListComponent
  },
  {
    path: 'front-end/objectives/objective-details',
    component: ObjectiveDetailsComponent
  },
  {
    path: 'add-city',
    component: AddCityComponent
  },

  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotpasswordComponent
  },


  {
    path: 'city-list',
    component: ListCityComponent
  },

  {
    path: 'city-details',
    component: DetailsCityComponent
  },

  {
    path: 'city-edit',
    component: EditCityComponent
  },
  {
    path: 'front-end/home/home-add',
    component: HomeAddComponent
  },
  {
    path: 'front-end/home/home-list',
    component: HomeListComponent
  },
  {
    path: 'front-end/home/home-edit',
    component: HomeEditComponent
  },
  {
    path: 'front-end/home/home-details',
    component: HomeDetailsComponent
  },
  {
    path: 'front-end/feedback/feedback-details',
    component: HomeDetailsComponent
  },
  {
    path: 'front-end/feedback/feedback-list',
    component: FeedbackListComponent
  },
  {
    path: 'front-end/feedback/feedback-edit',
    component: FeedbackEditComponent
  },
  {
    path: 'front-end/feedback/feedback-add',
    component: FeedbackAddComponent
  },
  {
    path: 'contact-edit',
    component: ContactEditComponent
  },
  {
    path: 'contact-add',
    component: ContactAddComponent
  },
  {
    path: 'contact-list',
    component: ContactListComponent
  },

  {
    path: 'inbox-add',
    component: InboxAddComponent
  },
  {
    path: 'inbox-edit',
    component: InboxEditComponent
  },
  {
    path: 'inbox-details',
    component: InboxDetailsComponent
  },
  {
    path: 'inbox-list',
    component: InboxListComponent
  },
  {
    path: 'sent-mail',
    component: SentMailComponent
  },
  {
    path: 'received-mail',
    component: ReceivedMailComponent
  },
  {
    path: 'signature-add',
    component: SignatureAddComponent
  },
  {
    path: 'signature-list',
    component: SignatureListComponent
  },
  {
    path: 'signature-edit',
    component: SignatureEditComponent
  },
  {
    path: 'signature-details',
    component: SignatureDetailsComponent
  },

  {
    path: 'service-add',
    component:ServiceAddComponent
  },
  
  {
    path: 'service-edit',
    component:ServiceEditComponent
  },
  
  {
    path: 'service-list',
    component: ServiceListComponent
  },

  {
    path: 'service-details',
    component: ServiceDetailsComponent
  },

  {
    path: 'our-team-add',
    component: OurTeamAddComponent
  },

  {
    path: 'our-team-edit',
    component: OurTeamEditComponent
  },

  {
    path: 'our-team-list',
    component: OurTeamListComponent
  },

  {
    path: 'our-team-details',
    component: OurTeamDetailsComponent
  },

  {
    path: 'our-team-director-add',
    component: OurTeamDirectorAddComponent
  },

  {
    path: 'our-team-director-edit',
    component:OurTeamDirectorEditComponent
  },
 
  {
    path: 'our-team-director-details',
    component: OurTeamDirectorDetailsComponent
  },

  {
    path: 'our-team-director-list',
    component: OurTeamDirectorListComponent
  },

  {
    path: 'company-overview-add',
    component:CompanyOverviewAddComponent
  },
  
  {
    path: 'company-overview-edit',
    component:CompanyOverviewEditComponent
  },
  
  {
    path: 'company-overview-details',
    component:CompanyOverviewDetailsComponent
  },
  
  {
    path: 'company-overview-list',
    component:CompanyOverviewListComponent
  },
  {
    path: 'general-list',
    component:GeneralListComponent
  },
  {
    path: 'general-view',
    component:GeneralViewComponent
  },
  {
    path: 'front-end/feedback-details',
    component:FeedbackDetailsComponent
  },
  {
    path: 'client-list-add',
    component:ClientListAddComponent
  },
  {
    path: 'client-list-data',
    component:ClientListDataComponent
  },
  {
    path: 'client-list-details',
    component:ClientListDetailsComponent
  },
  {
    path: 'client-list-edit',
    component:ClientListEditComponent
  },
  {
    path: 'client-status-list',
    component:ClientStatusListComponent
  },
  {
    path: 'client-project-status',
    component:ClientProjectStatusComponent
  },
  {
    path: 'conversation-report',
    component:ConversationReportComponent
  },
  {
    path: 'client-partner-add',
    component:ClientPartnersAddComponent
  },
  {
    path: 'client-partner-edit',
    component:ClientPartnersEditComponent
  },
  {
    path: 'client-partner-details',
    component:ClientPartnersDetailsComponent
  },
  {
    path: 'client-partner-list',
    component:ClientPartnersListComponent
  },
  {
    path: 'fire-occupancy-add',
    component:FireOccupencyAddComponent
  },
  {
    path: 'fire-occupancy-edit',
    component:FireOccupencyEditComponent
  },
  {
    path: 'fire-occupancy-details',
    component:FireOccupencyDetailsComponent
  },
  {
    path: 'fire-occupancy-list',
    component:FireOccupencyListComponent
  },
  {
    path: 'fire-renewal-add',
    component:FireRenewalAddComponent
  },
  {
    path: 'fire-renewal-edit',
    component:FireRenewalEditComponent
  },
  {
    path: 'fire-renewal-details',
    component:FireRenewalDetailsComponent
  },
  {
    path: 'fire-renewal-list',
    component:FireRenewalListComponent
  },
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
