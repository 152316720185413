import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-client-partners-details',
  templateUrl: './client-partners-details.component.html',
  styleUrls: ['./client-partners-details.component.scss']
})
export class ClientPartnersDetailsComponent implements OnInit {

  ourteam;
  constructor( private router:Router,
    public httpService:HttpServiceService, public alertService:AlertService,
    public util:UtilService,public sanitizer: DomSanitizer,
    public loaderService:LoaderService) {
    this.ourteam = JSON.parse(localStorage.getItem('clientListDetails'));
    console.log(this.ourteam)
  }

  ngOnInit() {
  }
  

  delete(data) {
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Client/delete_client_partner?id='+data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully deleted','Okay');
            this.router.navigate(['/client-partner-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })

  }
  edit(data) {
    console.log(data)
    localStorage.setItem('ClientListEditData', JSON.stringify(data));
    this.router.navigate(['/client-partner-edit'])
  }

}
