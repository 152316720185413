import { Component, OnInit } from '@angular/core';
//import { HttpService } from 'src/app/http.service';
//import { FirebaseDbService } from 'src/app/firebase-db.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  json:any={
  }
//  questions:any=[]
  constructor() {

   }

  ngOnInit() {
 /*   this.firebase.getDb().collection('homePageData').snapshotChanges().subscribe(res=>{
      this.json=(res[0].payload.doc.data())
      this.json['id'] = res[0].payload.doc.id
      }) */
  }

  submit(){
  //  console.log(this.json)
//    this.firebase.updateData('homePageData',this.json.id,this.json)
  }
}