import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit {

  contact;
  constructor( private router:Router,
    public httpService:HttpServiceService, public alertService:AlertService,
    public util:UtilService,
    public loaderService:LoaderService) {
    this.contact = JSON.parse(localStorage.getItem('contactDetails'))
  }

  ngOnInit() {
  }

  toggleStatus(id,information,phone,address,email,timings){
    let data = {
      information:information,
      phone:phone,
      address:address,
      email:email,
      timings:timings
    }
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{  
        this.httpService.postApi( data, 'client/update_contact_details' + id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('success','Successfully updated','Okay');
            this.router.navigate(['/front-end/contact/contact-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["success"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
  }


  
  edit(data) {
    console.log(data)
    localStorage.setItem('editData', JSON.stringify(data));
    this.router.navigate(['front-end/contact/contact-edit'])
  }

}
