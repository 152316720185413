import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {
  json: any=[];
  contactList:any = [];

  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService,
    public loaderService: LoaderService,private formBuilder:FormBuilder) {

  }

  ngOnInit() {
    this.loadData();
    
  }
  loadData(){
    this.contactList = []
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('/client/contact_list').subscribe((res: any) => {
          console.log(res)
          this.contactList = res.data ? res.data : []

          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }
  

  
  add() {
    this.router.navigate(['front-end/contact/contact-add'])
  }
 
// this.addHomeForm.value.about_us = elem.about_us
details(data) {
  console.log(data)
  localStorage.setItem('contactDetails', JSON.stringify(data));
  this.router.navigate(['front-end/contact/contact-details']) 
}



}
