import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-signature-add',
  templateUrl: './signature-add.component.html',
  styleUrls: ['./signature-add.component.scss']
})
export class SignatureAddComponent implements OnInit {

  signatureForm: FormGroup;
  loading;
  file: any;
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { }

  ngOnInit() {
    this.signatureForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    //  status: ['', [Validators.required]],
    client_list_image: [''],
            });
   
  }


  
  onFileSelect(event) {

    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  submit(data){
    let sendData:any = {};
    sendData = {
      name: data.name ? data.name : "",
      status: "1",
    }
    console.log(sendData)
    if (!this.signatureForm.valid) {
			this.util.enableFromValidation(this.signatureForm);
			return;
    }
    // data.otpNumber = "";
    // data.otpNumber = data.otp ? data.otp : ""
    //data['createdAt']= new Date().getTime();
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{
        const formData = new FormData();
        formData.append('client_list_image', this.file);
        console.log(this.file);
        this.httpService.postApiForImageSending(formData,`/Client/add_signature?name=${sendData.name}&status=${sendData.status}`).subscribe((res) => {
          this.loaderService.hideLoader();
          if(res["success"]){
            this.alertService.presentAlert('Success','Successfully added','Okay');
          this.router.navigate(['signature-list'])
          }else{
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }

}
