import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-client-status-list',
  templateUrl: './client-status-list.component.html',
  styleUrls: ['./client-status-list.component.scss']
})
export class ClientStatusListComponent implements OnInit {

  projectForm: FormGroup;
  loading;
  serviceTypes;
  cities=[{name:'Vijayawada',id:1}]
  clients
  status
  clientData:any
  projectData;
  allProjects:any={};
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { }

  ngOnInit() {
    this.projectForm = this.formBuilder.group({
                client: ['', [Validators.required]],
                city: ['', [Validators.required]],
                // serviceType: ['', [Validators.required]],
                isAvailable: ['', [Validators.required]],
                siteArea: ['', [Validators.required]],
                status: ['', [Validators.required]],
                date: ['', [Validators.required]],
                mail: ['', [Validators.required]],
                project_id: ['', []],
                service_type: ['', []],
            });
          this.clientData =  JSON.parse(localStorage.getItem('AlluserData'))
          console.log(this.clientData)
           // this.loaderService.showLoader('');

            this.httpService.getApi('Servicetype/getAllServices').subscribe((res: any) => {
              this.serviceTypes = res.success.servicetype;
              console.log( this.serviceTypes)
              
             });
             this.httpService.getApi('Client/getAllClients').subscribe((res: any) => {
              this.clients = res.success.client;
              console.log( this.clients)
             });
             this.getProject()
             this.getCitylist();
  }

  serviceTypeOnChange()
  {
    this.status = []
    let data=this.projectForm.value.service_type;
    console.log(data)
    this.httpService.postApi(data,'Status/getstatusById/' +data).subscribe((res: any) => {
      this.status = res.success;
  
      console.log(this.status)
     });
  }
  getFireObj:any = {}
  getCcfeObj:any = {}
  getecObj:any = {}
  airportObj:any = {}
  getServiceData(){
    this.getFireObj = {};
    this.getCcfeObj = {};
    this.getecObj = {};
    this.airportObj = {};
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{

        this.httpService.getApi(`/client/certificate_api?client_id=${this.clientData.id}&project_id=${this.projectForm.value.project_id}&service_type_id=${this.projectForm.value.service_type}`).subscribe((res: any) => {
          this.getFireObj = res.success? res.success : {};
          this.getCcfeObj = res.success? res.success : {};
          this.getecObj = res.success? res.success : {};
          this.airportObj = res.success? res.success : {};
          console.log(this.getFireObj)
          //  if(res["success"]){
          //   this.alertService.presentAlert('Success',res.success,'Okay');
          // }else{
          //   this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          // }

          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }

  

  submit(data){
    console.log(data)
    data['createdAt']= new Date().getTime();
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Project/add').subscribe((res) => {
          this.loaderService.hideLoader();
          if(res["success"]){
            this.alertService.presentAlert('Success','Successfully added','Okay');
            this.sendStatusClient();
            this.router.navigate(['/project-list'])
          }else{
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }
  cityList:any = []
  getCitylist(){
    this.cityList = []
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('/client/cities').subscribe((res: any) => {
          console.log(res)
          this.cityList = res.success ? res.success : []

          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }
  clientPhoneNumber:any = ""
  getClientNumber() {
    let clientId = "";
    this.clientPhoneNumber = "";
   clientId =  this.projectForm.value.client;
   this.clientPhoneNumber = this.clients ? this.clients.filter((v) => v.id == clientId )[0].mobileNumber : "";
   console.log(this.clientPhoneNumber)
  }

  statusName:any = "";
  getStatusName(){
    this.statusName = "";
    let status_id : any = "";
    status_id =  this.projectForm.value.status;
    this.statusName =  this.status ?  this.status.filter((v) => v.id ==  status_id)[0].id : "";
    console.log( this.statusName)


  }
  locationName:any = "";
  getCityNameOnChange(){
    this.locationName = "";
    let location_id:any = "";
    location_id = this.projectForm.value.city
    this.locationName = this.cityList ? this.cityList.filter(d=> d.id == location_id)[0].city_name : "";
    console.log(this.locationName)
  }

  sendStatusClient(){
    let obj = {
      mobileNumber : this.clientPhoneNumber ? this.clientPhoneNumber : "",
      message : this.statusName ? this.statusName : ""
    }
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{
        this.httpService.postApi(obj, `/client/status_api?service_type=${this.projectForm.value.service_type}&mobile_number=${this.clientPhoneNumber}&status_position=${this.statusName}&location=${this.locationName}`).subscribe((res) => {
          this.loaderService.hideLoader();
          // if(res["success"]){
          //   this.alertService.presentAlert('Success','Successfully added','Okay');
          //   // this.router.navigate(['/project-list'])
          // }else{
          //   this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          // }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }
  projectList:any = [];
  getProject(){

    // this.projectList = []
    // this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
    //   try{
    //     this.httpService.getApi(`/client/client_projects?client_id=${this.clientData.id}`).subscribe((res: any) => {
    //       console.log(res)
    //       this.projectList = res.success ? res.success : []
    //       this.loaderService.hideLoader();
    //      },(err)=>{
          
    //       this.loaderService.hideLoader();
    //       this.alertService.presentNetworkAlert();
    //      });
    //   }catch(e){
    //     this.loaderService.hideLoader();
    //   }
   
    // });

    this.httpService.postApi({client:this.clientData.id},'Client/getProjectFromProjectstatus/' +this.clientData.id ).subscribe(async(res: any) => {
      console.log(res)
       // this.json = res.success.projects;
       this.projectData = res.success.projects;
       console.log(this.projectData);
    await this.getAllProjects();
     
     });
  }

  getAllProjects() {
    
    return new Promise(resolve => {
      this.projectData.forEach(element => {
          this.httpService.postApi(element['project'], 'Project/getbyprojectProjectid/' + element['project']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.allProjects[element['project']] = res.success.project[0]
               console.log(this.allProjects)
            }
          });
      })
      resolve(1)      
    })
  }
}
