import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  serviceTypes: any = []
  json
  services: any = {}
  newList: Array<{ serviceTypes: string, json: number }> = []
  constructor(private formBuilder: FormBuilder, private router: Router,
    public util: UtilService,
    public httpService: HttpServiceService, public alertService: AlertService,
    public loaderService: LoaderService) { }

  async ngOnInit() {
    this.httpService.getApi('Servicetype/getAllServices').subscribe(async (res: any) => {
      if(res.success){
        this.serviceTypes = res.success.servicetype;
        console.log(this.serviceTypes)
        await this.getServices()
      }
    })
  }
  getServices() {
    console.log("services")
    return new Promise(resolve => {
      this.serviceTypes.forEach(element => {
          this.httpService.postApi(element['id'], 'KCertificate/getAcertificatesbyId/' + element['id']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.services[element['id']] = res.success.certificate
               console.log(this.services)
            }
          });
      })
      resolve(1)      
    })
  }
  // for(let i=0;i<res.success.servicetype.length;i++)
  // {
  //   console.log(this.serviceTypes[i]['id'])
  //   this.httpService.postApi(this.serviceTypes[i]['id'],'KCertificate/getAcertificatesbyId/' +this.serviceTypes[i]['id']).subscribe((res: any) => {
  //     this.json = res.success.certificate.length;
  //     console.log(this.json)
  //    });

  // }
  // this.newList.push({ serviceTypes: this.serviceTypes, json: this.json });
  // console.log(this.newList)
  // });

}

