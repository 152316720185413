import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-certificate-lists',
  templateUrl: './certificate-lists.component.html',
  styleUrls: ['./certificate-lists.component.scss']
})
export class CertificateListsComponent implements OnInit {
data
clients:any={}
  json: any=[];
  loginUser
  projects:any={}
  clientList:any=[]
  services:any={}
  projectList:any=[]
  projectStatus:any={}
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService, public sanitizer: DomSanitizer,
    public loaderService: LoaderService) {

  }

  ngOnInit() {

    this.loginUser =localStorage.getItem('userData')
    console.log(this.loginUser)

    this.httpService.postApi(this.loginUser,'KCertificate/getbyId/' +this.loginUser).subscribe(async(res: any) => {
     // this.json = res.success;
      if (res !=null) {
        console.log(res)
        this.json = res.success;
         console.log(this.json)
         await this.getclient()
         await this.getProject()
        await this.getServices()
       // await this.getstatus()
      }
      console.log( this.json)
     });
  }

  async getclient()
  {
    console.log("clients")
    return new Promise(resolve => {
      this.json.forEach(element => {
          this.httpService.postApi(element['client'], 'Client/getbyid/' + element['client']).subscribe(async(res: any) => {
            if (res.success) {
              console.log(res)
               this.clients[element['client']] = res.success.client
               console.log(this.clients)
               this.clientList.push(res.success.client[0]);
              
               console.log(this.clientList)
            }
          });
      })
      resolve(1)      
    })
  }


  getProject()
  {
  
    return new Promise(resolve => {
      this.json.forEach(element => {
        console.log("id",element['project'])
          this.httpService.postApi(element['project'], 'Project/getbyprojectProjectid/' + element['project']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.projects[element['project']] = res.success.project
               //this.projectList.push(res.success.project[0]);
               console.log(this.projects)
              //  this.getServices()
            }
          });
      })
      resolve(1)      
    })
  }


  getServices() {
    console.log("services")
    return new Promise(resolve => {
      this.json.forEach(element => {
          this.httpService.postApi(element['serviceType'], 'Servicetype/getServiceById/' + element['serviceType']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.services[element['serviceType']] = res.success.servicetype
               console.log(this.services)
            }
          });
      })
      resolve(1)      
    })
  }

  // getstatus()
  // {
  //   console.log("clients")
  //   return new Promise(resolve => {
  //     this.json.forEach(element => {
  //         this.httpService.postApi(element['status'], 'Status/getStatusBystatusid/' + element['status']).subscribe((res: any) => {
  //           if (res.success) {
  //            console.log(res)
  //              this.projectStatus[element['status']] = res.success
  //              console.log(this.projectStatus)
  //           }
  //         });
  //     })
  //     resolve(1)      
  //   })
  // }



  // download(data)
  // {
    
  // }
}
