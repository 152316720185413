import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';


@Component({
  selector: 'app-objective-edit',
  templateUrl: './objective-edit.component.html',
  styleUrls: ['./objective-edit.component.scss']
})
export class ObjectiveEditComponent implements OnInit {
  objectiveForm: FormGroup;
  editDetails;
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
    public httpService:HttpServiceService, public alertService:AlertService,
    public loaderService:LoaderService) {
    
  }

  ngOnInit() {
    this.objectiveForm = this.formBuilder.group({
           id: ['', [Validators.required]],
            question: ['', [Validators.required]],
            answer: ['', [Validators.required]],
            });
    this.loaderService.showLoader('Please wait while fetching ..').then(()=>{
    
    this.editDetails = JSON.parse(localStorage.getItem('editData'))
    //this.editDetails.question = "";
    //this.editDetails.answer = "";
    this.editDetails.question = this.editDetails.question ? this.editDetails.question : "";
    this.editDetails.answer = this.editDetails.answer ? this.editDetails.answer : "";
    console.log(this.editDetails)
    this.objectiveForm.patchValue(this.editDetails);
    this.loaderService.hideLoader();
  })

  }

  submit(data) {
    data['createdAt']= new Date().getTime();
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{ 
        this.httpService.postApi(data,`/client/objective_edit?question=${data.question}&answer=${data.answer}&id=${data.id})`).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["message"]) {
            this.alertService.presentAlert('message','Successfully updated','Okay');
            this.router.navigate(['front-end/objectives/objective-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }

  backToListList() {
    this.router.navigate(['/front-end/objectives/objective-list'])
  }

}
