import { Component, OnInit } from '@angular/core';
//import { HttpService } from 'src/app/http.service';
//import { FirebaseDbService } from 'src/app/firebase-db.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-feedback-edit',
  templateUrl: './feedback-edit.component.html',
  styleUrls: ['./feedback-edit.component.scss']
})
export class FeedbackEditComponent implements OnInit {

  feedback: any = {}
  feedForm: FormGroup;
  file:any
  
  constructor(private formBuilder:FormBuilder, private router:Router,
    public util:UtilService,
       public httpService:HttpServiceService, public alertService:AlertService,
       public loaderService:LoaderService) { }

  

  onFileSelect(event) {
    
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      console.log(this.file)
    }
  }
  feedBackLocalData:any = {}
  feedBackId:any = ""
  ngOnInit() {
  this.feedBackLocalData = JSON.parse(localStorage.getItem("feed-back-edit-list-data"))
  this.feedBackId = "";
  console.log(this.feedBackLocalData)
    this.feedForm = this.formBuilder.group({
      feed_back_image: [''],
      name:[''],
      designation:[''],
      review:['']

    });
  this.feedBackId =  this.feedBackLocalData.id
    this.feedForm.patchValue(this.feedBackLocalData);
  }

  
submit(data){
 
  console.log(this.feedForm.value)
  this.loaderService.showLoader('Adding Please wait ..').then(()=>{
    try{
      let obj = {

      }  
      console.log(this.file)
      const formData = new FormData();
      
      // formData.append('name',this.feedForm.get('name').value);
      // formData.append('designation',this.feedForm.get('designation').value);
      // formData.append('review',this.feedForm.get('review').value);
      formData.append('feed_back_image',this.file);
      // /feed_back_update?name=test&designation=test&review=test&id=1
      this.httpService.postApiForImageSending(formData,`client/feed_back_update?name=${this.feedForm.get('name').value}&designation=${this.feedForm.get('designation').value}&review=${this.feedForm.get('review').value}&id=${this.feedBackId}`).subscribe((res) => {
        this.loaderService.hideLoader();
        if(res["success"]){
          this.alertService.presentAlert('success','Successfully added','Okay');
          this.router.navigate(['/front-end/feedback/feedback-list']);
        }else{
          this.alertService.presentAlert('Error',res["error"]["success"],'Okay');
        }    
      },(err)=>{
        
        this.loaderService.hideLoader();
        this.alertService.presentNetworkAlert();
       });
    }catch(e){
      this.loaderService.hideLoader();
      this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
    }
  })
  
}

backToListList() {
  this.router.navigate(['/front-end/feedback/feedback-list'])
}

}

