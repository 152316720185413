import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-feedback-add',
  templateUrl: './feedback-add.component.html',
  styleUrls: ['./feedback-add.component.scss']
})
export class FeedbackAddComponent implements OnInit {
  feedForm: FormGroup;
  file:any
  
  constructor(private formBuilder:FormBuilder, private router:Router,
    public util:UtilService,
       public httpService:HttpServiceService, public alertService:AlertService,
       public loaderService:LoaderService) { }

  ngOnInit() {
    this.feedForm = this.formBuilder.group({
      feed_back_image: [''],
      name:[''],
      designation:[''],
      review:['']

    });
  }

 
  onFileSelect(event) {
    
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      console.log(this.file)
    }
  }
  
submit(data){
 
  console.log(this.feedForm.value)
  this.loaderService.showLoader('Adding Please wait ..').then(()=>{
    try{  
      console.log(this.file)
      const formData = new FormData();
      
      formData.append('name',this.feedForm.get('name').value);
      formData.append('designation',this.feedForm.get('designation').value);
      formData.append('review',this.feedForm.get('review').value);
      formData.append('feed_back_image',this.file);
      
      this.httpService.postApiForImageSending(formData,'Client/add_feed_data/').subscribe((res) => {
        this.loaderService.hideLoader();
        if(res["success"]){
          this.alertService.presentAlert('success','Successfully added','Okay');
          this.router.navigate(['/front-end/feedback/feedback-list'])
        }else{
          this.alertService.presentAlert('Error',res["error"]["success"],'Okay');
        }    
      },(err)=>{
        
        this.loaderService.hideLoader();
        this.alertService.presentNetworkAlert();
       });
    }catch(e){
      this.loaderService.hideLoader();
      this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
    }
  })
  
}

backToListList() {
  this.router.navigate(['/front-end/feedback/feedback-list'])
}

}
