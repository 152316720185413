import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-objective-add',
  templateUrl: './objective-add.component.html',
  styleUrls: ['./objective-add.component.scss']
})
export class ObjectiveAddComponent implements OnInit {
  objectiveForm: FormGroup;
  
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { }

  ngOnInit() {
    this.objectiveForm = this.formBuilder.group({
                question: ['', [Validators.required]],
                answer: ['', [Validators.required]],
              
            });
   
  }


  submit(data){
    //data['createdAt']= new Date().getTime();
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{  
        this.httpService.postApi(data, `/client/objective_add?question=${data.question}&answer=${data.answer}`).subscribe((res) => {
          this.loaderService.hideLoader();
          if(res["message"]){
            this.alertService.presentAlert('message','Successfully added','Okay');
            this.router.navigate(['/front-end/objectives/objective-list'])
          }else{
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }
}
