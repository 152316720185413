import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-our-team-list',
  templateUrl: './our-team-list.component.html',
  styleUrls: ['./our-team-list.component.scss']
})
export class OurTeamListComponent implements OnInit {
  ourteam: any = {};
  ourTeamList:any = [];

  json: any = [];
  constructor(private router: Router,
    public httpService: HttpServiceService, public alertService: AlertService,
    public util: UtilService, public sanitizer: DomSanitizer,
    public loaderService: LoaderService) {

  }
  
  ngOnInit() {
    this.loadData();
  }

   loadData() {
    this.ourTeamList = []
    this.loaderService.showLoader('Fetching Please wait ..').then(() => {
      try {
        this.httpService.getApi('client/our_team_list').subscribe(async (res: any) => {
          this.ourTeamList = res.data ? res.data : [];
          this.loaderService.hideLoader();
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
      }

    });
  }

  
  add() {
    this.router.navigate(['/our-team-add'])
  }

  details(data) {
    localStorage.setItem('ourteamDetails', JSON.stringify(data));
    this.router.navigate(['/our-team-details'])
  }


  getname() {
    console.log("ourteam")
    return new Promise(resolve => {
      this.json.forEach(element => {
        this.httpService.postApi(element['name'], 'Client/getbyid/' + element['name']).subscribe((res: any) => {
          if (res.success) {
            console.log(res)
            this.ourteam[element['name']] = res.success.name
            console.log(this.ourteam)
          }
        });
      })
      resolve(1)
    })
  }

}
