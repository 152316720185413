import { Injectable } from '@angular/core';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }

  showLoader(loaderText){
    return new Promise((resolve,reject)=>{
      // $('#preloader').show();
      resolve(1);
    })
  }

  hideLoader(){
    // $("#preloader").hide();
  }
}
