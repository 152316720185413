import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss']
})
export class FeedbackListComponent implements OnInit {
  feedbacks = []

  constructor(private formBuilder:FormBuilder, private router:Router,
    public util:UtilService,
       public httpService:HttpServiceService, public alertService:AlertService,
       public loaderService:LoaderService) { }
  ngOnInit() {
   // this.firebase.getDb().collection('feedbackData').snapshotChanges().subscribe(res => {

     this.getClientData();
    }
    add() {
      this.router.navigate(['front-end/feedback/feedback-add'])
    }  

  details(data) {
    
    localStorage.setItem('editFeedBackData', JSON.stringify(data));
    localStorage.setItem('feedbackDetails', JSON.stringify(data));
    this.router.navigate(['/front-end/feedback-details'])
  }

  clientFeedBackArray:any = []
  getClientData(){
   this.clientFeedBackArray = [];
   this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
     try{
       this.httpService.getApi('client/feedback_list').subscribe((res: any) => {
         this.clientFeedBackArray = res.data ? res.data : [];
         console.log(this.clientFeedBackArray);
         this.loaderService.hideLoader();
        },(err)=>{
         
         this.loaderService.hideLoader();
         this.alertService.presentNetworkAlert();
        });
     }catch(e){
       this.loaderService.hideLoader();
     }
  
   });
 }

}
