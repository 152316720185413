import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader.service';
import { HttpServiceService } from 'src/app/http-service.service';
import { AlertService } from 'src/app/alert.service';
//import { FirebaseDbService } from 'src/app/firebase-db.service';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.scss']
})
export class FeedbackDetailsComponent implements OnInit {

  feedbackDetails = {}
  constructor(public router:Router ,public loaderService:LoaderService,public httpService:HttpServiceService,public alertService:AlertService) {
  }

  ngOnInit() {
    this.feedbackDetails = JSON.parse(localStorage.getItem('feedbackDetails'))
    console.log( this.feedbackDetails)
  }
  delete(data) {
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, '/client/delete_feedback_list?id='+data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully deleted','Okay');
            this.backToListList();
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })

  }
edit(data){
  console.log(data)
  localStorage.setItem('feed-back-edit-list-data', JSON.stringify(data));
  this.router.navigate(['/front-end/feedback/feedback-edit'])
}

backToListList() {
  this.router.navigate(['/front-end/feedback/feedback-list'])
}
}
