import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm:FormGroup;
  otpForm:FormGroup;
  mobileNumber: any;
  registerForm: FormGroup;
  
  constructor(private formBuilder:FormBuilder, private router:Router, 
    public alertService:AlertService, public util:UtilService,
   
    public loaderService:LoaderService,
     public httpService:HttpServiceService) { 
     
      
     }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userName: ['',[Validators.required]],
      password: ['', [Validators.required]],
     // otpNumber: ['', [Validators.required]],
      otpNumber: ["", []],
      

	  
    });
    //this.loginForm.value.otpNumber = ""
    this.otpForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required,Validators.pattern(/^\d{10}$/)]],
      otpNumber: ['', []],

    });

    this.registerForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmpassword : ['',[]],
      mobileNumber: ['',[Validators.pattern(/^\d{10}$/)]],
      emailID: ['',[Validators.pattern(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )]]

    });

  }

  submit(data){
    console.log(data)
    // if(data.username != 0 && data.password == 0 && data.otpNumber == 0) {
    //   this.alertService.presentAlert('Error',"Please fill Password Or OTP ","Try Again");
    //     return false
    //  } 	
 
     	

    // if(data.password == ""  && data.otpNumber == undefined) {
    //   this.alertService.presentAlert('Error',"ffPlease fill Password Or OTP ","Try Again");
    //   return false
    // }
    // if(data.password != "" ||  data.otpNumber == "" &&  data.otpNumber == undefined) {
    //   this.alertService.presentAlert('Error',"Please fill Password Or OTP ","Try Again");
    //   return false
    // }
    let obj = {
      "username": data.userName ? data.userName : "",
      "password": data.password ? data.password : "",
      "otp": data.otpNumber ? data.otpNumber : ""

    }
    if (!this.loginForm.valid) {
			this.util.enableFromValidation(this.loginForm);
			return;
    }
  this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
    this.httpService.postApi(obj, `Client/login?username=${obj.username}&password=${obj.password}&otp=${obj.otp}`).subscribe((res) => {
      console.log(res);
     // localStorage.setItem(JSON.stringify('AlluserData',res["success"]))
      localStorage.setItem('AlluserData', JSON.stringify(res["success"]))
      if(res["success"]){
        
        this.loaderService.hideLoader();
        if(res["success"]['isAdmin']=="0"){
          
         
          localStorage.setItem('userData',(res["success"]['id']))
         
        this.router.navigate(['/ceritifaceLists'])
        }else{
          this.loaderService.hideLoader();
        
        }
        if((res["success"]['isAdmin']=="1"))
        {
          localStorage.setItem('userData',(res["success"]['id']))
         
          this.router.navigate(['/dashboard'])

        }
       
      }else{
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error',"Username/Password incorrect","Try Again");
      }    
      
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })
    
 
}
idOtpFieldVisible:boolean = false;
NewotpNumber:any ;
  submitt(data){
    this.idOtpFieldVisible = false
    
    // console.log(this.mobileNumber)
    // if(data.mobileNumber.lengn > 10 ){
    //   alert('Please fill 10 digit ')
    // }
    console.log(data)
    this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
    this.httpService.postApi(data, '/Client/login_with_otp?get_user_info='+ data.userName).subscribe((res) => {
      if(res.status){
        this.alertService.presentAlert('Success',`${res.message}`,"Success");
        this.idOtpFieldVisible = true;
      } else {
        this.idOtpFieldVisible = false
        this.alertService.presentAlert('Error',`${res.message}`,"Try Again");


      }
     // localStorage.setItem(JSON.stringify('AlluserData',res["success"]))
      // localStorage.setItem('AlluserData', JSON.stringify(res["success"]))
      // if(res["success"]){
        
      //   this.loaderService.hideLoader();
      //   if(res["success"]['isAdmin']=="0"){
          
      //     // sessionStorage.setItem('role',res["success"]['role']);
      //     // sessionStorage.setItem('beneficiaries',res["success"]['beneficiaryId']);
      //     // sessionStorage.setItem('id',res['success']['id'])
      //     localStorage.setItem('userData',(res["success"]['id']))
         
      //   this.router.navigate(['/ceritifaceLists'])
      //   }else{
      //     this.loaderService.hideLoader();
      //    // this.alertService.presentAlert('Account Blocked',"Please contact administrator","Try Again");
      //   }
      //   if((res["success"]['isAdmin']=="1"))
      //   {
      //     localStorage.setItem('userData',(res["success"]['id']))
         
      //     this.router.navigate(['/dashboard'])

      //   }
       
      // }else{
      //   this.loaderService.hideLoader();
      //   // this.alertService.presentAlert('Error',"mobileNumber incorrect","Try Again");
      // }    
      
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })
    
 
}

submittOTP(data){
  console.log(this.otpForm)
  console.log(this.NewotpNumber)
  console.log(data)
  this.idOtpFieldVisible = true;
  this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
    this.httpService.postApi(data,`Client/verify_otp?get_user_info=${data.mobileNumber}&otp=${data.otpNumber}`).subscribe((res) => {
      if(res.status){
        this.alertService.presentAlert('Success',`${res.message}`,"Success");
        // this.idOtpFieldVisible = true;
        this.loaderService.hideLoader();
        localStorage.setItem('AlluserData', JSON.stringify(res.data))
        if(res.data.isAdmin == "0"){
          localStorage.setItem('userData',(res.data.id))
        this.router.navigate(['/ceritifaceLists'])
        } else {
          this.loaderService.hideLoader();
        }

        if(res.data.isAdmin == "1"){ 
          localStorage.setItem('userData',(res.data.id))
          this.router.navigate(['/dashboard'])
        } else { 
          this.loaderService.hideLoader();
          this.alertService.presentAlert('Error',"Username/Password incorrect","Try Again");
        }

        // localStorage.setItem(JSON.stringify('AlluserData',"res.status"))
      } else {
        this.idOtpFieldVisible = false
        this.alertService.presentAlert('Error',`${res.message}`,"Try Again");


      }
      
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })
 

}

Register(value:any) {
  let localObj:any = {};
  localObj = value ? value: {}
  console.log(localObj);
  if(localObj.password !=""){
    if(localObj.password != localObj.confirmpassword) {
        this.alertService.presentAlert('Error',"Confirn Password Not Matched ! ","Try Again");
      return false
    }
    
  }
  // /client/client_registered?userName=karthik&password=1234&mobileNumber=12346&email=karthik@gmail.com
  this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
    this.httpService.postApi(localObj,`Client/client_registered?userName=${localObj.userName}&password=${localObj.password}&mobileNumber=${localObj.mobileNumber}&email=${localObj.emailID}`).subscribe((res) => {
      // if(res.status){
        this.alertService.presentAlert('Success',`${res.success}`,"Success");
        console.log(this.registerForm)
      //   // this.idOtpFieldVisible = true;
      //   this.loaderService.hideLoader();
        

      //   // localStorage.setItem(JSON.stringify('AlluserData',"res.status"))
      // } else {
      //   this.alertService.presentAlert('Error',`${res.message}`,"Try Again");
      // }
      
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })

}


}
