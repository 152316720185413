import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';


@Component({
  selector: 'app-our-team-director-edit',
  templateUrl: './our-team-director-edit.component.html',
  styleUrls: ['./our-team-director-edit.component.scss']
})
export class OurTeamDirectorEditComponent implements OnInit {

  ourteamdirectorForm: FormGroup;
  file:any
  ourTeamDirectorEdit:any = {};
  constructor(private formBuilder:FormBuilder, private router:Router,
    public util:UtilService,
       public httpService:HttpServiceService, public alertService:AlertService,
       public loaderService:LoaderService) { }

       ngOnInit() {
        this.ourteamdirectorForm = this.formBuilder.group({
          image: [''],
          name:[''],
          description:[''],
    
        });
        this.ourTeamDirectorEdit = {};
        this.ourTeamDirectorEdit = JSON.parse(localStorage.getItem('ourTeamDirectorEdit'));
        this.ourTeamDirectorEdit['image']=""
        console.log(this.ourTeamDirectorEdit)
        this.ourteamdirectorForm.patchValue(this.ourTeamDirectorEdit);
      }

      onFileSelect(event) {
    
        if (event.target.files.length > 0) {
          this.file = event.target.files[0];
        }
      }
      
    submit(data){
     
      console.log(this.ourteamdirectorForm.value)
      this.loaderService.showLoader('Adding Please wait ..').then(()=>{
        try{  
          const formData = new FormData();
          
          // formData.append('name',this.ourteamdirectorForm.get('name').value);
          // formData.append('description',this.ourteamdirectorForm.get('description').value);
          formData.append('our_team_d_image',this.file);
          this.httpService.postApiForImageSending(formData,`Client/update_our_team_d?description=${this.ourteamdirectorForm.get('description').value}&name=${this.ourteamdirectorForm.get('name').value}&id=${this.ourTeamDirectorEdit.id}`).subscribe((res) => {
            this.loaderService.hideLoader();
            if(res["success"]){
              this.alertService.presentAlert('success','Successfully added','Okay');
              this.router.navigate(['/our-team-director-list'])
            }else{
              this.alertService.presentAlert('Error',res["error"]["success"],'Okay');
            }    
          },(err)=>{
            
            this.loaderService.hideLoader();
            this.alertService.presentNetworkAlert();
           });
        }catch(e){
          this.loaderService.hideLoader();
          this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
        }
      })
      
    }


}
