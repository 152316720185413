import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-certificate-details',
  templateUrl: './certificate-details.component.html',
  styleUrls: ['./certificate-details.component.css']
})
export class CertificateDetailsComponent implements OnInit {

  certificate;
  clientName:any=[];
  json:any=[];
  constructor( private router:Router,
    public httpService:HttpServiceService, public alertService:AlertService,
    public util:UtilService,public sanitizer: DomSanitizer,
    public loaderService:LoaderService) {
    this.certificate = JSON.parse(localStorage.getItem('certificateDetails'))
  }

  ngOnInit() {
    this.httpService.postApi(this.certificate.client, 'Client/getbyid/' + this.certificate.client).subscribe((res: any) => {
      if (res.success) {
        console.log(res)
        this.clientName=res.success.client[0];
      }
    });

    this.httpService.postApi(this.certificate.serviceType, 'Servicetype/getServiceById/' + this.certificate.serviceType).subscribe((res: any) => {
     
      if (res["success"]) {
        this.json = res.success.servicetype.name;
        console.log("json",res.success.servicetype.name)
      } 
    });
    
  
  }

  toggleStatus(id,status){
    let data={
      isAvailable:status
    }
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'KCertificate/edit' + id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully updated','Okay');
            this.router.navigate(['/certificate-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
  }

  delete(data) {
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'KCertificate/remove/'+data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully deleted','Okay');
            this.router.navigate(['/certificate-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })

  }
  edit(data) {
    console.log(data)
    localStorage.setItem('editData', JSON.stringify(data));
    this.router.navigate(['/certificate-edit'])
  }



}
