import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-service-type-add',
  templateUrl: './service-type-add.component.html',
  styleUrls: ['./service-type-add.component.css']
})
export class ServiceTypeAddComponent implements OnInit {

  serviceTypeForm: FormGroup;
  loading;
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { }

  ngOnInit() {
    this.serviceTypeForm = this.formBuilder.group({
                name: ['', [Validators.required]],
               // isAvailable: ['1', [Validators.required]],
            });
   
  }


  submit(data){
    data['createdAt']= new Date().getTime();
    data['isAvailable']=1;
    if (!this.serviceTypeForm.valid) {
			this.util.enableFromValidation(this.serviceTypeForm);
			return;
    }
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Servicetype/add').subscribe((res) => {
          this.loaderService.hideLoader();
          if(res["success"]){
            this.alertService.presentAlert('Success','Successfully added','Okay');
            this.router.navigate(['/service-type-list'])
          }else{
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }
}
