import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-tax-invoice-details',
  templateUrl: './tax-invoice-details.component.html',
  styleUrls: ['./tax-invoice-details.component.scss']
})
export class TaxInvoiceDetailsComponent implements OnInit {

  taxInvoice;
  constructor( private router:Router,
    public httpService:HttpServiceService, public alertService:AlertService,
    public util:UtilService,
    public loaderService:LoaderService) {
    this.taxInvoice = JSON.parse(localStorage.getItem('taxInvoiceDetails'))
    console.log(this.taxInvoice)
  }

  ngOnInit() {
  }

  toggleStatus(id,status){
    let data={
      isAvailable:status
    }
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'taxInvoice/edit' + id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully updated','Okay');
            this.router.navigate(['/taxInvoice-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
  }

  delete(data) {
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'taxInvoice/remove/'+data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully deleted','Okay');
            this.router.navigate(['/taxInvoice-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })

  }
  edit(data) {
    console.log(data)
    localStorage.setItem('editData', JSON.stringify(data));
    this.router.navigate(['/taxInvoice-edit'])
  }

  download(data)
  {
    window.open(this.httpService.serverUrl+'Ecquote/test/' +data.id,'_blank')
  }

}
