import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit {
  service = []

  constructor(private formBuilder:FormBuilder, private router:Router,
    public util:UtilService,
       public httpService:HttpServiceService, public alertService:AlertService,
       public loaderService:LoaderService) { }
  ngOnInit() {
   // this.firebase.getDb().collection('serviceData').snapshotChanges().subscribe(res => {

     this.getClientData();
    }
    add() {
      this.router.navigate(['service-add'])
    }  

  details(data) {
    
    localStorage.setItem('ServiceDataDetsails', JSON.stringify(data));
    this.router.navigate(['service-details'])
  }

  clientServiceArray:any = []
  getClientData(){
   this.clientServiceArray = [];
   this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
     try{
       this.httpService.getApi('client/service_list').subscribe((res: any) => {
         this.clientServiceArray = res.data ? res.data : [];
         console.log(this.clientServiceArray);
         this.loaderService.hideLoader();
        },(err)=>{
         
         this.loaderService.hideLoader();
         this.alertService.presentNetworkAlert();
        });
     }catch(e){
       this.loaderService.hideLoader();
     }
  
   });
 }

 delete(data) {
  this.loaderService.showLoader('deleting Please wait ..').then(() => {
    try {
      this.httpService.postApiForImageSending(data, `client/delete_service_list?id=${data.id}`).subscribe((res: any) => {
        this.loaderService.hideLoader();
        // if (res["success"]) {
        //   this.alertService.presentAlert('message', 'Successfully updated', 'Okay');
        //   this.router.navigate(['service-list'])
        // } else {
        //   this.alertService.presentAlert('Error', "something went Wrong !", 'Okay');
        // }
      }, (err) => {

        this.loaderService.hideLoader();
        this.alertService.presentNetworkAlert();
      });
    } catch (e) {
      this.loaderService.hideLoader();
      this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
    }
  })

}

}
