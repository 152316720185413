import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-client-add',
  templateUrl: './client-add.component.html',
  styleUrls: ['./client-add.component.css']
})
export class ClientAddComponent implements OnInit {

  clientForm: FormGroup;
  loading;
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { }

  ngOnInit() {
    this.clientForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
                password: ['', [Validators.required]],
                gstNo: ['', [Validators.required]],
                companyName: ['', [Validators.required]],
                mobileNumber: ['', [Validators.required]],
                email: ['', [Validators.required]],
                address: ['', [Validators.required]],
                isAvailable: ['1', [Validators.required]],
                isAdmin:['0', [Validators.required]],
                panNo:['', [Validators.required]],
              //  otp:['-', [Validators.required]],
              // profile_pic:['-']
            });
   
  }


  submit(data){
  
    // data.otpNumber = "";
    // data.otpNumber = data.otp ? data.otp : ""
    data['createdAt']= new Date().getTime();
    if (!this.clientForm.valid) {
			this.util.enableFromValidation(this.clientForm);
			return;
    }
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Client/add').subscribe((res) => {
          this.loaderService.hideLoader();
          if(res["success"]){
            this.alertService.presentAlert('Success','Successfully added','Okay');
            this.router.navigate(['/client-list'])
          }else{
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }

}
