import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-client-list-data',
  templateUrl: './client-list-data.component.html',
  styleUrls: ['./client-list-data.component.scss']
})
export class ClientListDataComponent implements OnInit {

  ourteam: any = {};
  clientListData:any = [];

  json: any = [];
  constructor(private router: Router,
    public httpService: HttpServiceService, public alertService: AlertService,
    public util: UtilService, public sanitizer: DomSanitizer,
    public loaderService: LoaderService) {

  }
  
  ngOnInit() {
    this.loadData();
  }

   loadData() {
    this.clientListData = []
    this.loaderService.showLoader('Fetching Please wait ..').then(() => {
      try {
        this.httpService.getApi('Client/client_list_data').subscribe(async (res: any) => {
          this.clientListData = res.data ? res.data : [];
          this.loaderService.hideLoader();
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
      }

    });
  }

  
  add() {
    this.router.navigate(['/client-list-add'])
  }

  details(data) {
    localStorage.setItem('clientListDetails', JSON.stringify(data));
    this.router.navigate(['/client-list-details'])
  }

}
