import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-certificate-edit',
  templateUrl: './certificate-edit.component.html',
  styleUrls: ['./certificate-edit.component.css']
})
export class CertificateEditComponent implements OnInit {

  certificateForm: FormGroup;
  editDetails;
  status
  serviceTypes
  projects
  clients
 certificateArray:any={}
 certificateTypes
 projectData;
 allProjects:any={};
 file: any ;
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,public sanitizer: DomSanitizer,
    public httpService:HttpServiceService, public alertService:AlertService,
    public loaderService:LoaderService) {
    
  }

  ngOnInit() {
    // this.certificateForm = this.formBuilder.group({
    //   id: ['', [Validators.required]],
    //           status: ['', [Validators.required]],
    //             client: ['', [Validators.required]],
    //             certificate: ['', [Validators.required]],
    //             serviceType: ['', [Validators.required]],
    //             project: ['', [Validators.required]],
    //             renewelDays: ['', [Validators.required]],
    //             issueDate: ['', [Validators.required]],
    //             isAvailable: ['', [Validators.required]],
    //         });
    this.loaderService.showLoader('Please wait while fetching ..').then(()=>{
    
    this.editDetails = JSON.parse(localStorage.getItem('editData'))
   // this.getProjects()

    this.certificateArray=this.editDetails;
    this.getProjects();
    this.loaderService.hideLoader();
  })



  this.httpService.getApi('Servicetype/getAllServices').subscribe((res: any) => {
    this.serviceTypes = res.success.servicetype;
    console.log( this.serviceTypes)
    
   });


   this.httpService.getApi('Client/getAllClients').subscribe((res: any) => {
    this.clients = res.success.client;
    console.log( this.clients)
   });

   this.httpService.getApi('Status/getAllStatus').subscribe((res: any) => {
    this.status = res.success.status;
    console.log( this.clients)
   });


   this.httpService.getApi('certificatetype/getAllcertificatetype').subscribe((res: any) => {
    this.certificateTypes = res.success.certificatetype;
 
console.log( this.certificateTypes)

});

//  this.httpService.getApi('Project/getAllProjects').subscribe((res: any) => {
//   this.projects = res.success.project;
//   console.log( this.projects)
//  });
  }

  serviceTypeOnChange(data)
  {
    this.httpService.postApi(data,'Status/getstatusById/' +data).subscribe((res: any) => {
      this.status = res.success;
      this.getProjects()
      console.log(this.status)
     });
  }

  clientonChange(data)
  {
    this.getProjects();
  }

  getProjects()
  {
    if(this.certificateArray['serviceType'] && this.certificateArray['client']){
      let a=this.certificateArray['serviceType']
      let b=this.certificateArray['client']
      console.log(a,b)
    let  data=this.certificateArray;
      
      this.httpService.postApi(data,'Project/getprojectById/' +a +'/' +b).subscribe((res: any) => {
        this.projects = res.success;
        console.log(this.projects)
       });
      console.log(data)
    }
    
  }
  submit() {
    this.certificateArray['createdAt']= new Date().getTime();

    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        const formData = new FormData();
       
        formData.append("certificate", this.file);
        // this.httpService.postApi(this.certificateArray, 'KCertificate/edit/' + this.certificateArray.id).subscribe((res: any) => {
          this.httpService.postApiForImageSending(formData, `/KCertificate/edit?client=${this.certificateArray['client']}&serviceType=${this.certificateArray['serviceType']}&project=${this.certificateArray['project']}&id=${this.certificateArray.id}&issueDate=${this.certificateArray['issueDate']}&expiryDate=${this.certificateArray['expiryDate']}&renewelDays=${this.certificateArray['renewelDays']}&isAvailable=${this.certificateArray['isAvailable']}&createdAt=${this.certificateArray['createdAt']}`).subscribe((res) => {
        this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully updated','Okay');
            this.router.navigate(['/certificate-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }


  onFileSelect(event) {

    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }


  getAllProjects() {
    
    return new Promise(resolve => {
      this.projectData.forEach(element => {
          this.httpService.postApi(element['project'], 'Project/getbyprojectProjectid/' + element['project']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.allProjects[element['project']] = res.success.project[0]
               console.log(this.allProjects)
            }
          });
      })
      resolve(1)      
    })
  }


  get dateOfExpiry()
{
   let x=this.certificateArray['issueDate']
   var dt = new Date(x);

  let y=this.certificateArray['renewelDays']

  if(x!=null && y!=null)
  {
    let z=new Date(dt.setMonth(dt.getMonth() + parseInt(y)))
    var strDate1 = z.toISOString().slice(0,10);
   // console.log(strDate1);
   this.certificateArray['expiryDate']=strDate1;
    return z.toISOString().slice(0,10);
  }
 
// this.certificateArray['expiryDate']=strDate1;
   
}
}
