import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-quotation-version',
  templateUrl: './quotation-version.component.html',
  styleUrls: ['./quotation-version.component.scss']
})
export class QuotationVersionComponent implements OnInit {
  json: any=[];
  aaiQuote;
  constructor( private router:Router,
    public httpService:HttpServiceService, public alertService:AlertService,
    public util:UtilService,
    public loaderService:LoaderService) {
   // this.aaiQuote = JSON.parse(localStorage.getItem('aaiQuoteDetails'))
  }

  ngOnInit() {
    this.loadData()
  }

    loadData(){
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('Cfequote/getAll').subscribe((res: any) => {
         
          if (res.success) {
            console.log(res)
            this.json = res.success.cfequote;
             console.log(this.json)
            // await this.getclient()
          }
          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }




}
