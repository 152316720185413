import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-our-team-add',
  templateUrl: './our-team-add.component.html',
  styleUrls: ['./our-team-add.component.scss']
})
export class OurTeamAddComponent implements OnInit {
  ourteamForm: FormGroup;
  file: any

  constructor(private formBuilder: FormBuilder, private router: Router,
    public util: UtilService,
    public httpService: HttpServiceService, public alertService: AlertService,
    public loaderService: LoaderService) { }

  ngOnInit() {
    this.ourteamForm = this.formBuilder.group({
      image: [''],
      name: [''],
      description: [''],

    });
  }


  onFileSelect(event) {

    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  submit(data) {

    console.log(this.ourteamForm.value)
    this.loaderService.showLoader('Adding Please wait ..').then(() => {
      try {
        const formData = new FormData();

        // formData.append('name', this.ourteamForm.get('name').value);
        // formData.append('description', this.ourteamForm.get('description').value);
        formData.append('our_team_image', this.file);

        this.httpService.postApiForImageSending(formData, `/client/add_our_team?description=${this.ourteamForm.get('description').value}&name=${this.ourteamForm.get('name').value}`).subscribe((res) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('success', 'Successfully added', 'Okay');
            this.router.navigate(['/our-team-list'])
          } else {
            this.alertService.presentAlert('Error', res["error"]["success"], 'Okay');
          }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }
}
