import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/alert.service';
import { HttpServiceService } from 'src/app/http-service.service';
import { LoaderService } from 'src/app/loader.service';
import { UtilService } from 'src/app/util.service';

@Component({
  selector: 'app-received-mail',
  templateUrl: './received-mail.component.html',
  styleUrls: ['./received-mail.component.scss']
})
export class ReceivedMailComponent implements OnInit {
  serviceTypes:any=[];
  json: any=[];
  inboxList:any = []
  allClients;
  projectData;
  serviceData:any=[];
  inboxData;
  inboxArray:any={};
  clientSection='true';
  allProjects:any={}
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService,
    public loaderService: LoaderService) {
      this.clientData =  JSON.parse(localStorage.getItem('AlluserData'))
  }

  async loadData(){
    this.inboxList = []
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
       // this.httpService.getApi('Client/getClientByEmail/'+this.clientData.email).subscribe((res: any) => {
          this.httpService.postApi({email:this.clientData.email},'Client/getReceivedMail').subscribe(async(res: any) => {
          console.log(res)
          //this.inboxList = res.client ? res.client : []
          this.inboxList = res.client 
          await this.getserviceType();
          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }
  clientData:any = {}
  ngOnInit() {
    this.clientData =  JSON.parse(localStorage.getItem('AlluserData'))
    this.loadData();

    //get all clients
    this.httpService.getApi('Client/getAllClients').subscribe((res: any) => {
      console.log(res)
      this.allClients = res.success.client;
     });

     //get all service types
     this.httpService.getApi('Servicetype/getAllServices').subscribe((res: any) => {
      this.serviceData = res.success.servicetype ? res.success.servicetype : [];
      console.log(this.serviceData)
    });

    if(this.clientData.isAdmin == 0){
     
      this.clientSection=='false';
      //this.httpService.postApi({client:this.clientData.id},'Project/getbyprojectbyClientId/' +this.clientData.id ).subscribe((res: any) => {
        this.httpService.postApi({client:this.clientData.id},'Client/getProjectFromProjectstatus/' +this.clientData.id ).subscribe(async(res: any) => {
      console.log(res)
        this.projectData = res.success.projects;
         console.log(this.projectData)
         await this.getAllProjects();
     });
     
    }
  }
  // add() {
  //   this.router.navigate(['inbox-add'])
  // }
  details(data) {
    console.log(data)
    localStorage.setItem('inboxDetails', JSON.stringify(data));
    this.router.navigate(['/inbox-details']) 
  }


  getserviceType()
  {
    return new Promise(resolve => {
    
      this.inboxList.forEach(element => {
        element['servicetype']=  element['servicetype']==''?0:element['servicetype'];
          this.httpService.postApi(element['servicetype'], 'Servicetype/getServiceById/' + element['servicetype']).subscribe((res: any) => {
            if (res.success) {
             console.log(res)
               //this.projectStatus[element['status']] = res.success
              // console.log(this.projectStatus)
              this.serviceTypes[element['servicetype']]=res.success.servicetype;
            }
          });
        
      })
      resolve(1) 
        
    })
  }


  getProjects(data)
  {
    console.log(data);
   // this.httpService.postApi({client:data},'Project/getbyprojectbyClientId/' +data ).subscribe((res: any) => {
      this.httpService.postApi({client:data},'Client/getProjectFromProjectstatus/' +data ).subscribe(async(res: any) => {
      console.log(res)
      this.projectData = res.success.projects;
       console.log(this.projectData);
  await this.getAllProjects();
   });
  }

  getAllProjects() {
    
    return new Promise(resolve => {
      this.projectData.forEach(element => {
          this.httpService.postApi(element['project'], 'Project/getbyprojectProjectid/' + element['project']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.allProjects[element['project']] = res.success.project[0]
               console.log(this.allProjects)
            }
          });
      })
      resolve(1)      
    })
  }
 

  getmail(data)
  {
    let a=this.inboxArray['project']
    console.log(a,data);
    
    this.httpService.postApi({project:a,servicetype:data,email:this.clientData.email},'Client/getmailfromInbox/' ).subscribe((res: any) => {
      console.log(res)
     if(res.success.inbox.length){
      this.inboxList = res.success.inbox;
     }
     
     else{
      this.alertService.presentAlert('Error', "No Data Found", 'Okay');
     }
   
   });
  }


  clearFilters()
  {
    this.loadData();
  }
}
