import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  loginForm:FormGroup;
  otpForm:FormGroup;
  mobileNumber: any;
  registerForm: FormGroup;
  
  constructor(private formBuilder:FormBuilder, private router:Router, 
    public alertService:AlertService,public util:UtilService,
   
    public loaderService:LoaderService,
     public httpService:HttpServiceService) { 
     
      
     }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userName: ['',[Validators.required]],
      otpNumber: ['', []],
    });

    this.otpForm = this.formBuilder.group({
      otpNumber: ['', []],
      password : ['',[]],
      Confirmpassword: ['',[]]
    });
  }
  otpuserDetails:any = {};
  isPassowordFieldsVisible:boolean = false;
  submitt(data){
    if (!this.loginForm.valid) {
			this.util.enableFromValidation(this.loginForm);
			return;
    }
    this.isPassowordFieldsVisible = false;
    this.otpuserDetails = {}
    console.log(data)
    this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
    this.httpService.postApi(data, 'Client/forgot_password?get_user_info='+ data.userName).subscribe((res) => {
      if(res.status){
        this.alertService.presentAlert('Success',`${res.message}`,"Success");
        this.isPassowordFieldsVisible = true;
        this.otpuserDetails = res.data ? res.data : {};
        
      } else {
        this.alertService.presentAlert('Error',`${res.message}`,"Try Again");
        this.isPassowordFieldsVisible = false;


      }
     // localStorage.setItem(JSON.stringify('AlluserData',res["success"]))
      // localStorage.setItem('AlluserData', JSON.stringify(res["success"]))
      // if(res["success"]){
        
      //   this.loaderService.hideLoader();
      //   if(res["success"]['isAdmin']=="0"){
          
      //     // sessionStorage.setItem('role',res["success"]['role']);
      //     // sessionStorage.setItem('beneficiaries',res["success"]['beneficiaryId']);
      //     // sessionStorage.setItem('id',res['success']['id'])
      //     localStorage.setItem('userData',(res["success"]['id']))
         
      //   this.router.navigate(['/ceritifaceLists'])
      //   }else{
      //     this.loaderService.hideLoader();
      //    // this.alertService.presentAlert('Account Blocked',"Please contact administrator","Try Again");
      //   }
      //   if((res["success"]['isAdmin']=="1"))
      //   {
      //     localStorage.setItem('userData',(res["success"]['id']))
         
      //     this.router.navigate(['/dashboard'])

      //   }
       
      // }else{
      //   this.loaderService.hideLoader();
      //   // this.alertService.presentAlert('Error',"mobileNumber incorrect","Try Again");
      // }    
      
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })
    
 
}

submittOtp(data){

  this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
    this.httpService.postApi(data, `/client/reset_password?password=${data.password}&email=${this.otpuserDetails.email}&otp=${data.otpNumber}`).subscribe((res) => {
      if(res.status){
        this.alertService.presentAlert('Success',`${res.message}`,"Success");
        this.router.navigateByUrl('/login');
       
      } else {
        this.alertService.presentAlert('Error',`${res.message}`,"Try Again");


      }
     // localStorage.setItem(JSON.stringify('AlluserData',res["success"]))
      // localStorage.setItem('AlluserData', JSON.stringify(res["success"]))
      // if(res["success"]){
        
      //   this.loaderService.hideLoader();
      //   if(res["success"]['isAdmin']=="0"){
          
      //     // sessionStorage.setItem('role',res["success"]['role']);
      //     // sessionStorage.setItem('beneficiaries',res["success"]['beneficiaryId']);
      //     // sessionStorage.setItem('id',res['success']['id'])
      //     localStorage.setItem('userData',(res["success"]['id']))
         
      //   this.router.navigate(['/ceritifaceLists'])
      //   }else{
      //     this.loaderService.hideLoader();
      //    // this.alertService.presentAlert('Account Blocked',"Please contact administrator","Try Again");
      //   }
      //   if((res["success"]['isAdmin']=="1"))
      //   {
      //     localStorage.setItem('userData',(res["success"]['id']))
         
      //     this.router.navigate(['/dashboard'])

      //   }
       
      // }else{
      //   this.loaderService.hideLoader();
      //   // this.alertService.presentAlert('Error',"mobileNumber incorrect","Try Again");
      // }    
      
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })
}

}
