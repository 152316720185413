import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() title: string;
  user:any={}
  client1;
  constructor(private router: Router ,  public httpService:HttpServiceService
    ) { }
    notificationList:any = [];
  ngOnInit() {
  // this.user = localStorage.getItem('AlluserData')
    this.user = JSON.parse(localStorage.getItem('AlluserData'))
  // this.client1 = JSON.parse(localStorage.getItem('AlluserData1'))
  //    this.user=this.client1[0]
  //   console.log(this.user)
    this.getNotificationList();
  }
logout()
{
  localStorage.removeItem('userData')
  localStorage.removeItem('AlluserData')
  localStorage.clear();

this.router.navigate(['/login'])
}

getNotificationList(){
  this.notificationList = [];
    this.httpService.getApi('Client/notification_list').subscribe((res: any) => {
      this.notificationList = res.data ? res.data : []
      console.log(res);
     });
}

 myFunction()
{
  var x = document.getElementById("myLinks");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}

}
