import { Component, OnInit, Input } from '@angular/core';
import { HttpServiceService } from '../http-service.service';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent implements OnInit {

 
  @Input() param:any;
  constructor(public httpService:HttpServiceService,public loaderService:LoaderService) { }

  ngOnInit() {
  }

  changeListener($event){
    this.loaderService.showLoader('');
    this.readThisPhoto($event.target)
  }

  readThisPhoto(inputValue: any) {
    return new Promise(resolve=>{
      var file: File = inputValue.files[0];
      var myReader: FileReader = new FileReader();
  
      myReader.onloadend = (e) => {
       myReader.result;
        // this.categoryAddForm.patchValue({photo:myReader.result});
        this.httpService.postApi({ photo: myReader.result }, 'KCertificate/img_upload').subscribe((res) => {
          this.loaderService.hideLoader()
          if (res) {
            // res.success
            sessionStorage.setItem(this.param,res.success)
          } else {
            //this.modal.showModal({'success': false, 'message': res.error});
          }
        }, err => {
          this.loaderService.hideLoader()
          // this.modal.showModal({'success': false, 'message': err});
        });
      }
      myReader.readAsDataURL(file);
    })
    
  }


}
