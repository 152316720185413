import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.css']
})
export class ClientEditComponent implements OnInit {

  clientForm: FormGroup;
  editDetails;
  file:any;
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
    public httpService:HttpServiceService, public alertService:AlertService,
    public loaderService:LoaderService) {
    
  }
  clientId:any = "";
  clientData:any = {}
  ngOnInit() {
    this.clientId = ""
    this.clientForm = this.formBuilder.group({
      id: ['', [Validators.required]],
              userName: ['', [Validators.required]],
                password: ['', [Validators.required]],
                gstNo: ['', [Validators.required]],
                companyName: ['', [Validators.required]],
                mobileNumber: ['', [Validators.required]],
                email: ['', [Validators.required]],
                address: ['', [Validators.required]],
                panNo:['', [Validators.required]],
               // isAvailable: ['1', [Validators.required]],
            });
    this.loaderService.showLoader('Please wait while fetching ..').then(()=>{
      this.editDetails = JSON.parse(localStorage.getItem('editData'))
      this.clientForm.patchValue(this.editDetails);
      console.log(this.editDetails.id)
      this.clientId = this.editDetails.id
      this.loaderService.hideLoader();
      this.clientData =  JSON.parse(localStorage.getItem('AlluserData'))
  })

  }

  submit(data) {
    data['createdAt']= new Date().getTime();
    data['isAvailable']=1;
    this.updateProfilePic();
    if (!this.clientForm.valid) {
			this.util.enableFromValidation(this.clientForm);
			return;
    }
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Client/edit/' + data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
           // console.log(res["success"])
            this.alertService.presentAlert('Success','Successfully updated','Okay');
            if(this.clientData.isAdmin == "0"){
             // localStorage.setItem('AlluserData1', JSON.stringify([data]));
              this.router.navigate(['/client-profile'])
             
            }
            if(this.clientData.isAdmin == "1") { 
              this.router.navigate(['/client-list'])
            }
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      console.log( this.file)
    }
  }
  updateProfilePic() { 
    console.log(this.file)
    const formData = new FormData();
      formData.append("id",this.clientId);
      formData.append("client_pic",this.file);
      this.httpService.postApiForImageSending(formData,'Client/update_profile_pic').subscribe(data=>{},error=>{})
  }


}
