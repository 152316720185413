import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import { exit } from 'process';

@Component({
  selector: 'app-tax-invoice-add',
  templateUrl: './tax-invoice-add.component.html',
  styleUrls: ['./tax-invoice-add.component.scss']
})
export class TaxInvoiceAddComponent implements OnInit {

  taxInvoiceForm: FormGroup;
  loading;
invoiceArray:any={
  invoices:[]
}
sgstSelected=false;
igstSelected=false
clients:any=[]
banks=[{name:'Punjab National Bank',details:"Punjab National Bank,Current A/c-4636002100001685, IFSC:PUNB0463600<br>MVP Branch, Visakhapatnam, Andhra Pradesh"},
{name:'SBI Bank',details:"State Bank of India,A/C-39360770221,IFSC:SBIN0004158<br>Venkojipalem,Visakhapatnam-530017,Andhra Pradesh"}]
  
projectData;
allProjects:any={};
constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { 
      
     }

  ngOnInit() {
    this.httpService.getApi('Client/getAllClients').subscribe((res: any) => {
      this.clients = res.success.client;
      console.log( this.clients)
     });
     this.addInvoice()
  }

  
addInvoice(){
  this.invoiceArray.invoices.push({
    serialNo:'',
    description:'',
    unit:'',
    price:'',
    amount:'',
  })
}

removeInvoice(index){
  this.invoiceArray.invoices.splice(index,1)
  this.preTaxAmount()
}

  submit(){
    this.invoiceArray['invoices']=JSON.stringify(this.invoiceArray['invoices'])
    console.log(this.invoiceArray)

   
    this.invoiceArray['createdAt']= new Date().getTime();
    this.invoiceArray['isAvailable']= '1';
    this.invoiceArray['KnacktechGst']='37AAOFK1027F2ZF';
    
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{


        this.httpService.postApi(this.invoiceArray, 'TaxInvoice/add').subscribe((res) => {
          this.loaderService.hideLoader();
          if(res["success"]){
            window.open(this.httpService.serverUrl+'TaxInvoice/test/' +res["success"],'_blank')
        
            this.alertService.presentAlert('Success','Successfully added','Okay');
           this.router.navigate(['/taxInvoice-list'])
          }else{
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }


  // add()
  // {
  //   this.invoiceArray.totalAmount.push({
  //     sgst: '',
  //     cgst: '',
  //     igst: '',
     
  //   })
  //   console.log(this.invoiceArray);
  // }


  radioSelect(data)
  {
    console.log(this.invoiceArray['sgst']);
    this.sgstSelected=true;
  }

  changeGender(e) {
    console.log(e.target.value);
    if(e.target.value=="IGST")
   {
     
    this.igstSelected=true;
    this.sgstSelected=false;
 
   }
    
    else{
      this.sgstSelected=true;
      this.igstSelected=false;
     
    }
  }

  async calculatePrice(data,index)
  {
   var amount= (this.invoiceArray.invoices[index]['price'])*data;
   this.invoiceArray.invoices[index]['amount']=amount;
   this.preTaxAmount()
 
  this.invoiceArray['percentage']="";
  this.selectedPercentSgst(0);
  this.selectedPercentIgst(0);
   console.log(amount)
  }

 async calculatePrice1(data,index)
  {
    console.log(data,index)
   var amount= (this.invoiceArray.invoices[index]['unit'])*data;
 
   this.invoiceArray.invoices[index]['amount']=amount;
    this.preTaxAmount()
    this.invoiceArray['percentage']="";
    this.selectedPercentSgst(0);
    this.selectedPercentIgst(0);
  
    
  }

  preTaxAmount(){
    let tmpAmt = 0
    this.invoiceArray.invoices.forEach(element => { 
      tmpAmt = tmpAmt + element.amount
    });
    this.invoiceArray.totalPreTaxableAmount = tmpAmt
  }


  selectedPercentSgst(data)
  {
    //this.invoiceArray['cgst']=data;
    console.log(data);
    this.invoiceArray['gst']= this.invoiceArray['totalPreTaxableAmount']*(data/100);
    this.invoiceArray['cgst']=this.invoiceArray['gst']/2;
    this.invoiceArray['sgst_val']=this.invoiceArray['gst']/2;
    this.invoiceArray['totalAmount']=this.invoiceArray['totalPreTaxableAmount'] + this.invoiceArray['gst']
    console.log(this.invoiceArray['totalAmount'])
    this.numberToWords(this.invoiceArray['totalAmount']);
  }
  selectedPercentIgst(data)
  {
   // this.invoiceArray['igst']=data;
    this.invoiceArray['gst']= this.invoiceArray['totalPreTaxableAmount']*(data/100);
    
    this.invoiceArray['igst']=this.invoiceArray['gst'];
    this.invoiceArray['totalAmount']=this.invoiceArray['totalPreTaxableAmount'] + this.invoiceArray['gst']
    console.log(this.invoiceArray['totalAmount'])
  
    this.numberToWords(this.invoiceArray['totalAmount']);
  }


  getClientDetails(data)
  {
    console.log(data)
   
      this.httpService.postApi(data,'Client/getbyid/'  +data).subscribe((res: any) => {
       // this.clients = res.success.client;
        console.log(this.clients)
        if(res.success)
        {
         // this.invoiceArray['gst']=res.success.client[0].gstNo;
          this.invoiceArray['gstNo']=res.success.client[0].gstNo;
          this.invoiceArray['address']=res.success.client[0].address;
          this.invoiceArray['pan']=res.success.client[0].panNo;
         this.getProjects(data);
        }
      
       });
    
  
    
  }

  numberToWords(data)
  { 
    console.log(data);
    this.httpService.postApi(data,'taxInvoice/numberToWords/'  +data).subscribe((res: any) => {
      
       if(res)
       {
        console.log(res)
        this.invoiceArray['amountInWords']=res;
       }
     
      });
  }


  getProjects(data)
  {
    console.log(data);
   // this.httpService.postApi({client:data},'Project/getbyprojectbyClientId/' +data ).subscribe((res: any) => {
      this.httpService.postApi({client:data},'Client/getProjectFromProjectstatus/' +data ).subscribe(async(res: any) => {
      console.log(res)
      this.projectData = res.success.projects;
       console.log(this.projectData);
  await this.getAllProjects();
   });
  }


  getAllProjects() {
    
    return new Promise(resolve => {
      this.projectData.forEach(element => {
          this.httpService.postApi(element['project'], 'Project/getbyprojectProjectid/' + element['project']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.allProjects[element['project']] = res.success.project[0]
               console.log(this.allProjects)
            }
          });
      })
      resolve(1)      
    })
  }
}
