import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-inbox-add',
  templateUrl: './inbox-add.component.html',
  styleUrls: ['./inbox-add.component.scss']
})
export class InboxAddComponent implements OnInit {
  cli='true';
  AAIopens='false';
  Fireopens='false';
  ECopens='false';
  CFEopens='false';
  CFOopens='false';
  RERAopens='false';
  documentSection='false'
  inboxForm: FormGroup;
  file: any = [];
  myFiles:any = []
  clients:any;
  client=true
  filesToUpload: Array<File> = [];
  list
  projectList:any=[];
  constructor(private formBuilder: FormBuilder, private router: Router,
    public util: UtilService,
    public httpService: HttpServiceService, public alertService: AlertService,
    public loaderService: LoaderService) { }
    clientData:any = {}
  ngOnInit() {
    this.inboxForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      msg: ['', [Validators.required]],
      sender_id: [this.clientData.email, [Validators.required]],
      receiver_id: ['', [Validators.required]],
      servicetype: ['', []],
      status:['1',[]],
      inbox_attachment:['',[]],
      project:['',[]]
    });
    this.httpService.getApi('Client/getAllClients').subscribe((res: any) => {
      this.clients = res.success.client;
      console.log( this.clients)
     });
    this.clientData =  JSON.parse(localStorage.getItem('AlluserData'))
   // console.log(this.clientData.id);
    if(this.clientData.isAdmin == 0){
      this.documentSection=='true';
      this.inboxForm.patchValue({'email': 'noc@knacktechgroup.com'})
      this.httpService.postApi({client:this.clientData.id},'Project/getbyprojectbyClientId/' +this.clientData.id ).subscribe((res: any) => {
        console.log(res)
        this.projectList = res.success.projects;
         console.log(this.projectList)
     
     });
     
    }else {
      this.inboxForm.patchValue({'email': ''})
     
    }
    this.getServiceType();


 
  }

  // onClientChange(data)
  // {
  //   //console.log(data)
  // return data;
 
  // }

  getProjects(data)
  {
    console.log(data);
        this.httpService.postApi({email:data},'Client/getClientBymailId').subscribe((res: any) => {
      console.log(res.success.client.id)
      this.list = res.success.client.id;
      // console.log(this.projectList)
      this.httpService.postApi({id:this.list},'Project/getbyprojectbyClientId/' +this.list ).subscribe((res: any) => {
        console.log(res)
        this.projectList = res.success.projects;
         console.log(this.projectList)
     
     });
   });
   
  }

  submit(data) {
    let status = data.status == "0" ? 'Active':'In-Active'
    // if (!this.inboxForm.valid) {
		// 	this.util.enableFromValidation(this.inboxForm);
		// 	return;
    // }
    this.loaderService.showLoader('Adding Please wait ..').then(() => {
      try {
        const formData = new FormData();
        // for (var i = 0; i < this.myFiles.length; i++) { 
        // }
        // formData.append("inbox_attachment", this.myFiles);
        //formData.append('inbox_attachment', this.file);
        for (var i = 0; i < this.file.length; i++) { 
          formData.append("inbox_attachment[]", this.file[i]);
        }
        console.log(formData)
        this.httpService.postApiForImageSending(formData, `/client/add_inbox?email=${data.email}&msg=${data.msg}&sender_id=${this.clientData.email}&receiver_id=${this.inboxForm.value.email}&servicetype=${data.servicetype}&project=${data.project}&status=${status}&id=`).subscribe((res) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('message', 'Successfully added', 'Okay');
            this.router.navigate(['/received-mail'])
          } else {
            this.alertService.presentAlert('Error', "something went wrong ", 'Okay');
          }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }
  serviceTypeList: any = []
  getServiceType() {
    this.httpService.getApi('/Servicetype/getAllServices').subscribe((res: any) => {
      this.serviceTypeList = res.success.servicetype ? res.success.servicetype : [];
    });
  }

  onFileSelect(event) {
    // this.file = []
    // if (event.target.files.length > 0) {
    //   this.file = event.target.files;
    // }
    // console.log(this.file)
    for (var i = 0; i < event.target.files.length; i++) { 
      this.file.push(event.target.files[i]);
  }
  }

  servicetypeChange(data)
  {
    this.documentSection='true';
    console.log(data);
    if(data==1)
    {
      this.AAIopens='true';
  this.Fireopens='false';
  this.ECopens='false';
  this.CFEopens='false';
  this.CFOopens='false';
  this.RERAopens='false';
    }
    if(data==2)
    {
this.Fireopens='true';

this.AAIopens='false';

this.ECopens='false';
this.CFEopens='false';
this.CFOopens='false';
this.RERAopens='false';

    }
    if(data==3)
    {
      this.ECopens='true';

      this.AAIopens='false';
this.Fireopens='false';

this.CFEopens='false';
this.CFOopens='false';
this.RERAopens='false';
    }
    if(data==4)
    {
      this.CFEopens='true';

      this.AAIopens='false';
this.Fireopens='false';
this.ECopens='false';
this.CFOopens='false';
this.RERAopens='false';
    }
    if(data==5)
    {
      this.CFOopens='true';
      this.AAIopens='false';
this.Fireopens='false';
this.ECopens='false';
this.CFEopens='false';
this.RERAopens='false';
    }
    if(data==6)
    {
      this.RERAopens='true';
      this.AAIopens='false';
this.Fireopens='false';
this.ECopens='false';
this.CFEopens='false';
this.CFOopens='false';

    }
  }

}
