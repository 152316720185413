import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-client-partners-add',
  templateUrl: './client-partners-add.component.html',
  styleUrls: ['./client-partners-add.component.scss']
})
export class ClientPartnersAddComponent implements OnInit {

  clientPartnersForm: FormGroup;
  file: any

  constructor(private formBuilder: FormBuilder, private router: Router,
    public util: UtilService,
    public httpService: HttpServiceService, public alertService: AlertService,
    public loaderService: LoaderService) { }

  ngOnInit() {
    this.clientPartnersForm = this.formBuilder.group({
      client_list_image: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description:['', [Validators.required]],
    });
  }


  onFileSelect(event) {

    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  submit(data) {

    console.log(this.clientPartnersForm.value)
    if (!this.clientPartnersForm.valid) {
			this.util.enableFromValidation(this.clientPartnersForm);
			return;
    }
    this.loaderService.showLoader('Adding Please wait ..').then(() => {
      try {
        const formData = new FormData();
        formData.append('client_list_image', this.file);

        this.httpService.postApiForImageSending(formData, `Client/add_clientPartner?name=${this.clientPartnersForm.get('name').value}&description=${this.clientPartnersForm.get('description').value}`).subscribe((res) => {
          this.loaderService.hideLoader();
        //  if (res) {
            this.alertService.presentAlert('success', 'Successfully added', 'Okay');
            this.router.navigate(['/client-partner-list'])
          // } else {
          //   this.alertService.presentAlert('Error', 'Data not Added', 'Okay');
          // }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }

}
