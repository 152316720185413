import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';


@Component({
  selector: 'app-service-edit',
  templateUrl: './service-edit.component.html',
  styleUrls: ['./service-edit.component.scss']
})
export class ServiceEditComponent implements OnInit {
  serviceForm: FormGroup;
  editDetails;
  constructor(private formBuilder: FormBuilder, private router: Router,
    public util: UtilService,
    public httpService: HttpServiceService, public alertService: AlertService,
    public loaderService: LoaderService) {

  }

  ngOnInit() {
    this.serviceForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      description: ['', [Validators.required]],
      link: ['', [Validators.required]],
      image: ['', [Validators.required]],
      name : ["",[]]
    });
    this.loaderService.showLoader('Please wait while fetching ..').then(() => {

      this.editDetails = JSON.parse(localStorage.getItem('editServiceData'))
      //this.editDetails.question = "";
      //this.editDetails.answer = "";
      this.editDetails.description = this.editDetails.description ? this.editDetails.description : "";
      this.editDetails.link = this.editDetails.link ? this.editDetails.link : "";
      console.log(this.editDetails)
      this.serviceForm.patchValue(this.editDetails);
      this.loaderService.hideLoader();
    })

  }

  fileName:any = "";
  file: any
  onFileSelect(event) {
    this.fileName = "";
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.fileName = event.target.files[0]

    }
  }

  submit(data) {
    data['createdAt'] = new Date().getTime();
    const formData = new FormData();

    formData.append('service_image', this.file);
    // formData.append('description', this.serviceForm.get('description').value);
    // formData.append('link', this.serviceForm.get('link').value);
    this.loaderService.showLoader('Updating Please wait ..').then(() => {
      try {
        this.httpService.postApiForImageSending(formData, `/client/update_service?name=${data.name}&description=${data.description}&link=${data.link}&id=${this.editDetails.id}`).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('message', 'Successfully updated', 'Okay');
            this.router.navigate(['service-list'])
          } else {
            this.alertService.presentAlert('Error', "something went Wrong !", 'Okay');
          }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }


}
