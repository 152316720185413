import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-rera-quote-details',
  templateUrl: './rera-quote-details.component.html',
  styleUrls: ['./rera-quote-details.component.scss']
})
export class ReraQuoteDetailsComponent implements OnInit {
  json:any=[]
  clientData:any = {}
  clientName:any=[];
  reraQuote;
  constructor( private router:Router,
    public httpService:HttpServiceService, public alertService:AlertService,
    public util:UtilService,
    public loaderService:LoaderService) {
    this.reraQuote = JSON.parse(localStorage.getItem('reraQuoteDetails'))
  }

  ngOnInit() {
    this.clientData =  JSON.parse(localStorage.getItem('AlluserData'))
this.getQuoteVersion(this.reraQuote.id);
this.httpService.postApi(this.reraQuote.client, 'Client/getbyid/' + this.reraQuote.client).subscribe((res: any) => {
  if (res.success) {
    console.log(res)
    this.clientName=res.success.client[0];
  }
});
  }

  toggleStatus(id,status){
    let data={
      isAvailable:status
    }
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Reraquote/edit' + id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully updated','Okay');
            this.router.navigate(['/reraQuote-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
  }

  delete(data) {
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Reraquote/remove/'+data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully deleted','Okay');
            this.router.navigate(['/reraQuote-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })

  }
  edit(data) {
    console.log(data)
    localStorage.setItem('editData', JSON.stringify(data));
    this.router.navigate(['/reraQuote-edit'])
  }

  // download(data)
  // {
  //   window.open(this.httpService.serverUrl+'Ecquote/test/' +data.id,'_blank')
  // }

  getQuoteVersion(data)
  {
 
          this.httpService.postApi(data, 'Reraquote/getvesions/' + data).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.json = res.success.versions
              
            }
          });
    
   
  }
}
