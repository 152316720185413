import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-status-edit',
  templateUrl: './status-edit.component.html',
  styleUrls: ['./status-edit.component.css']
})
export class StatusEditComponent implements OnInit {

  statusForm: FormGroup;
  editDetails;
  serviceTypes:any=[]
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
    public httpService:HttpServiceService, public alertService:AlertService,
    public loaderService:LoaderService) {
    
  }

  ngOnInit() {
    this.statusForm = this.formBuilder.group({
      id: ['', [Validators.required]],
              serviceType: ['', [Validators.required]],
                status: ['', [Validators.required]],
                statusno: ['', [Validators.required]],
               // isAvailable: ['1', [Validators.required]],
            });

            this.httpService.getApi('Servicetype/getAllServices').subscribe((res: any) => {
              console.log(res)
              this.serviceTypes = res.success.servicetype
              console.log(this.serviceTypes)
            })
    this.loaderService.showLoader('Please wait while fetching ..').then(()=>{
    
    this.editDetails = JSON.parse(localStorage.getItem('editData'))
    this.statusForm.patchValue(this.editDetails);
    this.loaderService.hideLoader();
  })

  }

  submit(data) {
    data['createdAt']= new Date().getTime();
    data['isAvailable']=1;
    if (!this.statusForm.valid) {
			this.util.enableFromValidation(this.statusForm);
			return;
    }
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Status/edit/' + data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully updated','Okay');
            this.router.navigate(['/status-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }


}
