import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-client-list-edit',
  templateUrl: './client-list-edit.component.html',
  styleUrls: ['./client-list-edit.component.scss']
})
export class ClientListEditComponent implements OnInit {

  ourteamForm: FormGroup;
  file: any
clientLiistObj :any = {}
  constructor(private formBuilder: FormBuilder, private router: Router,
    public util: UtilService,
    public httpService: HttpServiceService, public alertService: AlertService,
    public loaderService: LoaderService) { }

  ngOnInit() {
    this.clientLiistObj = {}
   
    this.ourteamForm = this.formBuilder.group({
      client_list_image: [''],
      name: [''],
      city: [''],

    });
  this.clientLiistObj = JSON.parse(localStorage.getItem('ClientListEditData'))
    this.ourteamForm.patchValue(this.clientLiistObj);
    this.getCitylist();
  }

  onFileSelect(event) {

    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  submit(data) {

    console.log(this.ourteamForm.value)
    if (!this.ourteamForm.valid) {
			this.util.enableFromValidation(this.ourteamForm);
			return;
    }
    this.loaderService.showLoader('Adding Please wait ..').then(() => {
      try {
        const formData = new FormData();
        formData.append('client_list_image', this.file);

        this.httpService.postApiForImageSending(formData, `/client/client_list_update_data?name=${this.ourteamForm.get('name').value}&id=${this.ourteamForm.get('city').value}&id=${this.clientLiistObj.id}`).subscribe((res) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('success', 'Successfully added', 'Okay');
            this.router.navigate(['/client-list-data'])
          } else {
            this.alertService.presentAlert('Error', res["error"]["success"], 'Okay');
          }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }


  cityList:any = []
  getCitylist(){
    this.cityList = []
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
        this.httpService.getApi('/client/cities').subscribe((res: any) => {
          console.log(res)
          this.cityList = res.success ? res.success : []

          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }
}
