import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  otpSend = true;
  otpVerify=true;
  timer = 0;
  minutes = 0;
  otp = 0;
  open='false';
  opens='false';
  numberOpen='true';
  localData;
  error="";
  forgotPasswordForm:FormGroup;
  loginForm:FormGroup;
  otpForm:FormGroup;
  isPassowordFieldsVisible:boolean = false;
  constructor(private router:Router,private formBuilder:FormBuilder,
    public httpService:HttpServiceService, public alertService:AlertService,
    public util:UtilService,
    public loaderService:LoaderService) { }
    user:any = {};
  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      userName: ['',[Validators.required]],
      otpNumber: ['', []],
    });

    this.otpForm = this.formBuilder.group({
      otpNumber: ['', []],
      password : ['',[]],
      Confirmpassword: ['',[]]
    });
    this.user = JSON.parse(localStorage.getItem('AlluserData'));
    this.user.mobileNumber = this.user.mobileNumber ? this.user.mobileNumber : "0000000000"
    console.log(this.user)
    this.loginForm.patchValue({userName :this.user.mobileNumber})
    this.forgotPasswordForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    
     
      otp: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      password:['', [Validators.required,Validators.minLength(6)]],
    //  confirmPassword:['', [Validators.required,,Validators.minLength(6)]]
     

    });
  }


  removeError(){
    this.error="";
    this.otpSend=true;
  }

  ngOnDestroy(){
    this.otpSend=true;
    this.removeError();
    this.open='';
  }
  
  //data['phoneNumber']=this.forgotPasswordForm.value.phoneNumber;
  sendOtp(data){
   
console.log(data)
   this.otpSend=true;
   this.error="";
    this.httpService.postApi(data['mobileNumber'],'Client/getClientByNumber').subscribe((res: any) => {
     console.log(res.success.client.id);
    
     if(!res.success.client){
       this.error="Cannot find your account";
       this.forgotPasswordForm.reset();
      // return;
       this.otpSend=true;
     }
     data['otp']=res.success.client.otp;
     data['id']=res.success.client.id;
     data['password']=res.success.client.password
      if(res.success.client.mobileNumber)
      {
     
          this.httpService.postApi(data,'Client/edit/' + data.id).subscribe((res: any) => {
            console.log(res);
            if(res.success){
              this.open = 'true';
              console.log(this.open); 
                console.log("send otp");
             }
             else{
              this.alertService.presentAlert('Error',res["message"],'Okay');
             }
            })
          }
      else{
        console.log("entered mobile number doesnot exist");
      }
    })
    this.otpSend = false;
    this.otpVerify = true;
   
  }



  verifyOtp(data){
   
    //data['id']="5ea80baf729f5d60a56550f0";
    data['mobileNumber']=this.forgotPasswordForm.value.mobileNumber;
    this.httpService.postApi(data['mobileNumber'],'Client/getClientByNumber').subscribe((res: any) => {
      localStorage.setItem('verifyotp', JSON.stringify(res.success.client));
     console.log(res.success.client)
      if(res.success.client.otp==this.forgotPasswordForm.value.otp)
      {
        
        this.opens = 'true';
        this.open='false';
        this.numberOpen='false';
       // this.otpSend=false;
        console.log(this.opens);
        this.otpVerify = false;
         // console.log("send otp");
      }
      else{
        console.log("otp entered is wrong");
        this.alertService.presentAlert('Error','Wrong OTP is entered','Okay');
        this.otpVerify =true;
      }
    })
    this.otpVerify = false;
  }


  submit(data)
  {
    this.localData = JSON.parse(localStorage.getItem('verifyData'))
    data['mobileNumber']=this.forgotPasswordForm.value.mobileNumber;
    console.log("localData",this.localData);
    this.httpService.postApi(data['mobileNumber'],'Client/getClientByNumber').subscribe((res: any) => {
     console.log(res.success.client.id);
    console.log(res.success.client)
      data['id']=res.success.client.id;
    console.log(data)
    if(data.password)
    {
      console.log("passwords matched");
      this.httpService.postApi(data, 'Client/edit/' + data.id).subscribe((res: any) => {
        this.loaderService.hideLoader();
        if (res["success"]) {
          this.alertService.presentAlert('Success','Password changed Successfully','Okay');
       
        } else {
          this.alertService.presentAlert('Error',"passwords do not match",'Okay');
          console.log("not matched")
        }
      },(err)=>{
        
        this.loaderService.hideLoader();
        this.alertService.presentNetworkAlert();
       });
    }
  })
  }

  otpuserDetails:any = {};

  submitt(data){
    this.isPassowordFieldsVisible = false;
    this.otpuserDetails = {}
    this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
    this.httpService.postApi(data, '/client/forgot_password?get_user_info='+ data.userName).subscribe((res) => {
      if(res.status){
        this.alertService.presentAlert('Success',`${res.message}`,"Success");
        this.isPassowordFieldsVisible = true;
        this.otpuserDetails = res.data ? res.data : {};
        
      } else {
        this.alertService.presentAlert('Error',`${res.message}`,"Try Again");
        this.isPassowordFieldsVisible = false;
      }
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })
    
 
}

submittOtp(data){
  this.loaderService.showLoader('Please wait while we authenticate').then(()=>{
    this.httpService.postApi(data, `/client/reset_password?password=${data.password}&email=${this.otpuserDetails.email}&otp=${data.otpNumber}`).subscribe((res) => {
      if(res.status){
        this.alertService.presentAlert('Success',`${res.message}`,"Success");
        this.router.navigateByUrl('/ceritifaceLists');
       
      } else {
        this.alertService.presentAlert('Error',`${res.message}`,"Try Again");
      }
    });
  },err=>{
    this.loaderService.hideLoader();
    this.alertService.presentNetworkAlert();
  })
}


}
