import { Component, OnInit } from '@angular/core';
//import { HttpService } from 'src/app/http.service';
//import { FirebaseDbService } from 'src/app/firebase-db.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
//json:any={}
  constructor() { }

  ngOnInit() {
   
  }
  submit(){
    //console.log(this.json)
    //this.firebase.updateData('conactDetails',this.json.id,this.json)
  }
}
