import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css']
})
export class ProjectEditComponent implements OnInit {

  projectForm: FormGroup;
  editDetails;
  serviceTypes;
  cities: any = []
  clients
  status
  constructor(private formBuilder: FormBuilder, private router: Router,
    public util: UtilService,
    public httpService: HttpServiceService, public alertService: AlertService,
    public loaderService: LoaderService) {

  }

  ngOnInit() {
    this.projectForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      client: ['', [Validators.required]],
      city: ['', [Validators.required]],
      serviceType: ['', [Validators.required]],
     // isAvailable: ['1', [Validators.required]],
      projectAddress: ['', [Validators.required]],
      siteArea: ['', [Validators.required]],
      status: ['', [Validators.required]],
      date: ['', [Validators.required]],
      mail: ['',[]],
    });

    this.editDetails = JSON.parse(localStorage.getItem('Project-edit-data'))
    console.log(this.editDetails)



    // })

    this.projectForm.patchValue(this.editDetails);
    this.httpService.getApi('Servicetype/getAllServices').subscribe((res: any) => {
      this.serviceTypes = res.success.servicetype;

    });




    //  this.httpService.getApi('Status/getAllStatus').subscribe((res: any) => {
    //   this.clients = res.success.status;
    //  });
    this.getClientList();
    this.getCityList();
    this.serviceTypeOnChange();
    //  this.clallChangeMethods()
  }

  getCityList() {
    this.httpService.getApi('/client/cities').subscribe((res: any) => {
      this.cities = res.success ? res.success : []
      this.getCityNameOnChange()
    });
  }


  getClientList() {
    this.httpService.getApi('Client/getAllClients').subscribe((res: any) => {
      this.clients = res.success.client;
      this.getClientNumber();
    });
  }


  serviceTypeOnChange() {
    let data = ""
    data = this.projectForm.value.serviceType ? this.projectForm.value.serviceType : ""
    this.httpService.postApi(data, 'Status/getstatusById/' + data).subscribe((res: any) => {
      this.status = res.success;
      this.getStatusName()
    });
  }


  submit(data) {
    data['createdAt'] = new Date().getTime();
    data['siteArea'] =  this.editDetails.siteArea;
    //data['mail'] = new Date().getTime();
    data['isAvailable']=1;
    data['projectAddress'] =  this.editDetails.projectAddress;
    if (!this.projectForm.valid) {
			this.util.enableFromValidation(this.projectForm);
			return;
    }
    this.loaderService.showLoader('Updating Please wait ..').then(() => {
      try {
        this.httpService.postApi(data, 'Project/edit/' + data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.sendStatusClient();
            this.alertService.presentAlert('Success', 'Successfully updated', 'Okay');
            this.router.navigate(['/project-list'])
          } else {
            this.alertService.presentAlert('Error', res["error"]["message"], 'Okay');
          }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }

  clientPhoneNumber: any = ""
  getClientNumber() {
    let clientId = "";
    this.clientPhoneNumber = "";
    clientId = this.projectForm.value.client;
    this.clientPhoneNumber = this.clients ? this.clients.filter((v) => v.id == clientId)[0].mobileNumber : "";
  }

  statusName: any = "";
  getStatusName() {
    this.statusName = "";
    let status_id: any = "";
    status_id = this.projectForm.value.status;
    this.statusName = this.status ? this.status.filter((v) => v.id == status_id)[0].id : "";


  }

  locationName: any = "";
  getCityNameOnChange() {
    this.locationName = "";
    let location_id: any = "";
    console.log(this.projectForm.value.city)
    location_id = this.projectForm.value.city
    this.locationName = this.cities ? this.cities.filter(d => d.id == location_id)[0].city_name : "";
  }

  sendStatusClient() {
    let obj = {
      mobileNumber: this.clientPhoneNumber ? this.clientPhoneNumber : "",
      message: this.statusName ? this.statusName : ""
    }
    this.loaderService.showLoader('Adding Please wait ..').then(() => {
      try {
        this.httpService.postApi(obj, `/client/status_api?service_type=${this.projectForm.value.serviceType}&mobile_number=${this.clientPhoneNumber}&status_position=${this.statusName}&date=${this.projectForm.value.date}&location=${this.locationName}&client_id=${this.projectForm.value.client}&project_id=${this.projectForm.value.id}&status_name=${this.projectForm.value.status}`).subscribe((res) => {
          this.loaderService.hideLoader();
          // if(res["success"]){
          //   this.alertService.presentAlert('Success','Successfully added','Okay');
          //   // this.router.navigate(['/project-list'])
          // }else{
          //   this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          // }    
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }

}
