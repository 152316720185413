import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientAddComponent } from './pages/client/client-add/client-add.component';
import { ClientEditComponent } from './pages/client/client-edit/client-edit.component';
import { ClientDetailsComponent } from './pages/client/client-details/client-details.component';
import { ClientListComponent } from './pages/client/client-list/client-list.component';
import { ServiceTypeAddComponent } from './pages/serviceType/service-type-add/service-type-add.component';
import { ServiceTypeEditComponent } from './pages/serviceType/service-type-edit/service-type-edit.component';
import { ServiceTypeDetailsComponent } from './pages/serviceType/service-type-details/service-type-details.component';
import { ServiceTypeListComponent } from './pages/serviceType/service-type-list/service-type-list.component';
import { ProjectAddComponent } from './pages/project/project-add/project-add.component';
import { ProjectEditComponent } from './pages/project/project-edit/project-edit.component';
import { ProjectDetailsComponent } from './pages/project/project-details/project-details.component';
import { ProjectListComponent } from './pages/project/project-list/project-list.component';
import { CertificateAddComponent } from './pages/certificate/certificate-add/certificate-add.component';
import { CertificateEditComponent } from './pages/certificate/certificate-edit/certificate-edit.component';
import { CertificateListComponent } from './pages/certificate/certificate-list/certificate-list.component';
import { CertificateDetailsComponent } from './pages/certificate/certificate-details/certificate-details.component';
import { StatusAddComponent } from './pages/status/status-add/status-add.component';
import { StatusEditComponent } from './pages/status/status-edit/status-edit.component';
import { StatusDetailsComponent } from './pages/status/status-details/status-details.component';
import { StatusListComponent } from './pages/status/status-list/status-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EcQuoteAddComponent } from './pages/ecQuote/ec-quote-add/ec-quote-add.component';
import { EcQuoteEditComponent } from './pages/ecQuote/ec-quote-edit/ec-quote-edit.component';
import { EcQuoteDetailsComponent } from './pages/ecQuote/ec-quote-details/ec-quote-details.component';
import { EcQuoteListComponent } from './pages/ecQuote/ec-quote-list/ec-quote-list.component';
import { AaiQuoteAddComponent } from './pages/aaiQuote/aai-quote-add/aai-quote-add.component';
import { AaiQuoteEditComponent } from './pages/aaiQuote/aai-quote-edit/aai-quote-edit.component';
import { AaiQuoteDetailsComponent } from './pages/aaiQuote/aai-quote-details/aai-quote-details.component';
import { AaiQuoteListComponent } from './pages/aaiQuote/aai-quote-list/aai-quote-list.component';
import { FireQuoteAddComponent } from './pages/fireQuote/fire-quote-add/fire-quote-add.component';
import { FireQuoteEditComponent } from './pages/fireQuote/fire-quote-edit/fire-quote-edit.component';
import { FireQuoteDetailsComponent } from './pages/fireQuote/fire-quote-details/fire-quote-details.component';
import { FireQuoteListComponent } from './pages/fireQuote/fire-quote-list/fire-quote-list.component';
import { TaxInvoiceAddComponent } from './pages/taxInvoice/tax-invoice-add/tax-invoice-add.component';
import { TaxInvoiceEditComponent } from './pages/taxInvoice/tax-invoice-edit/tax-invoice-edit.component';
import { TaxInvoiceListComponent } from './pages/taxInvoice/tax-invoice-list/tax-invoice-list.component';
import { TaxInvoiceDetailsComponent } from './pages/taxInvoice/tax-invoice-details/tax-invoice-details.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CertificateTypeAddComponent } from './pages/certificateType/certificate-type-add/certificate-type-add.component';
import { CertificateTypeEditComponent } from './pages/certificateType/certificate-type-edit/certificate-type-edit.component';
import { CertificateTypeDetailsComponent } from './pages/certificateType/certificate-type-details/certificate-type-details.component';
import { CertificateTypeListComponent } from './pages/certificateType/certificate-type-list/certificate-type-list.component';
import { CertificateListsComponent } from './pages/certificate-lists/certificate-lists.component';
import { LoginComponent } from './pages/login/login.component';
import { ReraQuoteAddComponent } from './pages/reraQuote/rera-quote-add/rera-quote-add.component';
import { ReraQuoteEditComponent } from './pages/reraQuote/rera-quote-edit/rera-quote-edit.component';
import { ReraQuoteListComponent } from './pages/reraQuote/rera-quote-list/rera-quote-list.component';
import { ReraQuoteDetailsComponent } from './pages/reraQuote/rera-quote-details/rera-quote-details.component';
import { CfoQuoteAddComponent } from './pages/cfoQuote/cfo-quote-add/cfo-quote-add.component';
import { CfoQuoteEditComponent } from './pages/cfoQuote/cfo-quote-edit/cfo-quote-edit.component';
import { CfoQuoteDetailsComponent } from './pages/cfoQuote/cfo-quote-details/cfo-quote-details.component';
import { CfoQuoteListComponent } from './pages/cfoQuote/cfo-quote-list/cfo-quote-list.component';
import { CfeQuotAddComponent } from './pages/cfeQuote/cfe-quot-add/cfe-quot-add.component';
import { CfeQuotEditComponent } from './pages/cfeQuote/cfe-quot-edit/cfe-quot-edit.component';
import { CfeQuotDetailsComponent } from './pages/cfeQuote/cfe-quot-details/cfe-quot-details.component';
import { CfeQuotListComponent } from './pages/cfeQuote/cfe-quot-list/cfe-quot-list.component';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { ClientStatusReportListComponent } from './pages/clientStatusReport/client-status-report-list/client-status-report-list.component';
import { UpdateProfileComponent } from './pages/profile/update-profile/update-profile.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeComponent } from './pages/front-end/home/home.component';
import { ContactComponent } from './pages/front-end/contact/contact.component';
import { ObjectiveAddComponent } from './pages/front-end/objectives/objective-add/objective-add.component';
import { ObjectiveEditComponent } from './pages/front-end/objectives/objective-edit/objective-edit.component';
import { ObjectiveListComponent } from './pages/front-end/objectives/objective-list/objective-list.component';
import { ObjectiveDetailsComponent } from './pages/front-end/objectives/objective-details/objective-details.component';
import { AddCityComponent } from './pages/city/add-city/add-city.component';
import { RegisterComponent } from './pages/register/register.component';
import { ListCityComponent } from './pages/city/city-list/city-list.component';
import { EditCityComponent } from './pages/city/city-edit/city-edit.component';
import { DetailsCityComponent } from './pages/city/city-details/city-details.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';

import { ContactAddComponent } from './pages/front-end/contact/contact-add/contact-add.component';
import { ContactListComponent } from './pages/front-end/contact/contact-list/contact-list.component';
import { ContactDetailsComponent } from './pages/front-end/contact/contact-details/contact-details.component';
import { AboutAddComponent } from './pages/front-end/about/about-add/about-add.component';
import { AboutEditComponent } from './pages/front-end/about/about-edit/about-edit.component';
import { AboutListComponent } from './pages/front-end/about/about-list/about-list.component';
import { AboutDetailsComponent } from './pages/front-end/about/about-details/about-details.component';
import { HomeAddComponent } from './pages/front-end/home/home-add/home-add.component';
import { HomeEditComponent } from './pages/front-end/home/home-edit/home-edit.component';
import { HomeListComponent } from './pages/front-end/home/home-list/home-list.component';
import { HomeDetailsComponent } from './pages/front-end/home/home-details/home-details.component';
import { FeedbackAddComponent } from './pages/front-end/feedback/feedback-add/feedback-add.component';
import { FeedbackEditComponent } from './pages/front-end/feedback/feedback-edit/feedback-edit.component';
import { FeedbackListComponent } from './pages/front-end/feedback/feedback-list/feedback-list.component';
import { FeedbackDetailsComponent } from './pages/front-end/feedback/feedback-details/feedback-details.component';
import { ContactEditComponent } from './pages/front-end/contact/contact-edit/contact-edit.component';
import { InboxAddComponent } from './pages/inbox/inbox-add/inbox-add.component';
import { InboxEditComponent } from './pages/inbox/inbox-edit/inbox-edit.component';
import { InboxListComponent } from './pages/inbox/inbox-list/inbox-list.component';
import { InboxDetailsComponent } from './pages/inbox/inbox-details/inbox-details.component';
import { GeneralAddComponent } from './pages/general/general-add/general-add.component';
import { SignatureAddComponent } from './pages/signature/signature-add/signature-add.component';
import { SignatureEditComponent } from './pages/signature/signature-edit/signature-edit.component';
import { SignatureListComponent } from './pages/signature/signature-list/signature-list.component';
import { SignatureDetailsComponent } from './pages/signature/signature-details/signature-details.component';
import { ServiceAddComponent } from './pages/front-end/services/service-add/service-add.component';
import { ServiceEditComponent } from './pages/front-end/services/service-edit/service-edit.component';
import { ServiceListComponent } from './pages/front-end/services/service-list/service-list.component';
import { ServiceDetailsComponent } from './pages/front-end/services/service-details/service-details.component';
import { OurTeamAddComponent } from './pages/front-end/our-teams/our-team-add/our-team-add.component';
import { OurTeamEditComponent } from './pages/front-end/our-teams/our-team-edit/our-team-edit.component';
import { OurTeamListComponent } from './pages/front-end/our-teams/our-team-list/our-team-list.component';
import { OurTeamDetailsComponent } from './pages/front-end/our-teams/our-team-details/our-team-details.component';
import { OurTeamDirectorAddComponent } from './pages/front-end/our-team-director/our-team-director-add/our-team-director-add.component';
import { OurTeamDirectorEditComponent } from './pages/front-end/our-team-director/our-team-director-edit/our-team-director-edit.component';
import { OurTeamDirectorDetailsComponent } from './pages/front-end/our-team-director/our-team-director-details/our-team-director-details.component';
import { OurTeamDirectorListComponent } from './pages/front-end/our-team-director/our-team-director-list/our-team-director-list.component';
import { CompanyOverviewAddComponent } from './pages/front-end/company-overview/company-overview-add/company-overview-add.component';
import { CompanyOverviewEditComponent } from './pages/front-end/company-overview/company-overview-edit/company-overview-edit.component';
import { CompanyOverviewDetailsComponent } from './pages/front-end/company-overview/company-overview-details/company-overview-details.component';
import { CompanyOverviewListComponent } from './pages/front-end/company-overview/company-overview-list/company-overview-list.component';
import { GeneralListComponent } from './pages/general/general-list/general-list.component';
import { GeneralViewComponent } from './pages/general/general-view/general-view.component';
import { ClientListAddComponent } from './pages/front-end/client-list/client-list-add/client-list-add.component';
import { ClientListDetailsComponent } from './pages/front-end/client-list/client-list-details/client-list-details.component';
import { ClientListEditComponent } from './pages/front-end/client-list/client-list-edit/client-list-edit.component';
import { ClientListDataComponent } from './pages/front-end/client-list/client-list-data/client-list-data.component';
import { ClientStatusListComponent } from './pages/client-status-list/client-status-list.component';
import { SentMailComponent } from './pages/inbox/sent-mail/sent-mail.component';
import { ReceivedMailComponent } from './pages/inbox/received-mail/received-mail.component';
import { ClientProjectStatusComponent } from './pages/client-project-status/client-project-status.component';
import { ConversationReportComponent } from './pages/conversation-report/conversation-report.component';
import { ClientPartnersAddComponent } from './pages/clientPartners/client-partners-add/client-partners-add.component';
import { ClientPartnersEditComponent } from './pages/clientPartners/client-partners-edit/client-partners-edit.component';
import { ClientPartnersDetailsComponent } from './pages/clientPartners/client-partners-details/client-partners-details.component';
import { ClientPartnersListComponent } from './pages/clientPartners/client-partners-list/client-partners-list.component';
import { QuotationVersionComponent } from './pages/quotation-version/quotation-version.component';
import { FireOccupencyAddComponent } from './pages/fireOccupencyQuote/fire-occupency-add/fire-occupency-add.component';
import { FireOccupencyEditComponent } from './pages/fireOccupencyQuote/fire-occupency-edit/fire-occupency-edit.component';
import { FireOccupencyDetailsComponent } from './pages/fireOccupencyQuote/fire-occupency-details/fire-occupency-details.component';
import { FireOccupencyListComponent } from './pages/fireOccupencyQuote/fire-occupency-list/fire-occupency-list.component';
import { FireRenewalAddComponent } from './pages/fireRenewalQuote/fire-renewal-add/fire-renewal-add.component';
import { FireRenewalEditComponent } from './pages/fireRenewalQuote/fire-renewal-edit/fire-renewal-edit.component';
import { FireRenewalListComponent } from './pages/fireRenewalQuote/fire-renewal-list/fire-renewal-list.component';
import { FireRenewalDetailsComponent } from './pages/fireRenewalQuote/fire-renewal-details/fire-renewal-details.component';


@NgModule({
  declarations: [
    AppComponent,
    ClientAddComponent,
    ClientEditComponent,
    ClientDetailsComponent,
    ClientListComponent,
    ServiceTypeAddComponent,
    ServiceTypeEditComponent,
    ServiceTypeDetailsComponent,
    ServiceTypeListComponent,
    ProjectAddComponent,
    ProjectEditComponent,
    ProjectDetailsComponent,
    ProjectListComponent,
    CertificateAddComponent,
    CertificateEditComponent,
    CertificateListComponent,
    CertificateDetailsComponent,
    StatusAddComponent,
    StatusEditComponent,
    StatusDetailsComponent,
    StatusListComponent,
    EcQuoteAddComponent,
    EcQuoteEditComponent,
    EcQuoteDetailsComponent,
    EcQuoteListComponent,
    AaiQuoteAddComponent,
    AaiQuoteEditComponent,
    AaiQuoteDetailsComponent,
    AaiQuoteListComponent,
    FireQuoteAddComponent,
    FireQuoteEditComponent,
    FireQuoteDetailsComponent,
    FireQuoteListComponent,
    TaxInvoiceAddComponent,
    TaxInvoiceEditComponent,
    TaxInvoiceListComponent,
    TaxInvoiceDetailsComponent,
    HeaderComponent,
    DashboardComponent,
    CertificateTypeAddComponent,
    CertificateTypeEditComponent,
    CertificateTypeDetailsComponent,
    CertificateTypeListComponent,
    CertificateListsComponent,
    LoginComponent,
    ReraQuoteAddComponent,
    ReraQuoteEditComponent,
    ReraQuoteListComponent,
    ReraQuoteDetailsComponent,
    CfoQuoteAddComponent,
    CfoQuoteEditComponent,
    CfoQuoteDetailsComponent,
    CfoQuoteListComponent,
    CfeQuotAddComponent,
    CfeQuotEditComponent,
    CfeQuotDetailsComponent,
    CfeQuotListComponent,
    FileuploadComponent,
    ClientStatusReportListComponent,
    UpdateProfileComponent,
    ForgotPasswordComponent,
    HomeComponent,
    ContactComponent,
    ObjectiveAddComponent,
    ObjectiveEditComponent,
    ObjectiveListComponent,
    ObjectiveDetailsComponent,
    AddCityComponent,
    RegisterComponent,
    ListCityComponent,
    EditCityComponent,
    DetailsCityComponent,
    ForgotpasswordComponent,
    
    ContactEditComponent,
    ContactAddComponent,
    ContactListComponent,
    ContactDetailsComponent,
    AboutAddComponent,
    AboutEditComponent,
    AboutListComponent,
    AboutDetailsComponent,
    InboxAddComponent,
    InboxEditComponent,
    InboxListComponent,
    InboxDetailsComponent,
    HomeAddComponent,
    HomeDetailsComponent,
    HomeEditComponent,
    HomeListComponent,
    FeedbackAddComponent,
    FeedbackDetailsComponent,
    FeedbackEditComponent,
    FeedbackListComponent,
    InboxAddComponent,
    InboxDetailsComponent,
    InboxListComponent,
    InboxEditComponent,
    GeneralAddComponent,
    SignatureAddComponent,
    SignatureDetailsComponent,
    SignatureListComponent,
    SignatureEditComponent,
    ServiceAddComponent,
    ServiceEditComponent,
    ServiceListComponent,
    ServiceDetailsComponent,
    OurTeamAddComponent,
    OurTeamEditComponent,
    OurTeamListComponent,
    OurTeamDetailsComponent,
    OurTeamDirectorAddComponent,
    OurTeamDirectorEditComponent,
    OurTeamDirectorDetailsComponent,
    OurTeamDirectorListComponent,
    CompanyOverviewAddComponent,
    CompanyOverviewEditComponent,
    CompanyOverviewDetailsComponent,
    CompanyOverviewListComponent,
    GeneralListComponent,
    GeneralViewComponent,
    ClientListAddComponent,
    ClientListDetailsComponent,
    ClientListEditComponent,
    ClientListDataComponent,
    ClientStatusListComponent,
    SentMailComponent,
    ReceivedMailComponent,
    ClientProjectStatusComponent,
    ConversationReportComponent,
    ClientPartnersAddComponent,
    ClientPartnersEditComponent,
    ClientPartnersDetailsComponent,
    ClientPartnersListComponent,
    QuotationVersionComponent,
    FireOccupencyAddComponent,
    FireOccupencyEditComponent,
    FireOccupencyDetailsComponent,
    FireOccupencyListComponent,
    FireRenewalAddComponent,
    FireRenewalEditComponent,
    FireRenewalListComponent,
    FireRenewalDetailsComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
