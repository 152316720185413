import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-inbox-list',
  templateUrl: './inbox-list.component.html',
  styleUrls: ['./inbox-list.component.scss']
})
export class InboxListComponent implements OnInit {
  serviceTypes:any=[];
  json: any=[];
  inboxList:any = []
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService,
    public loaderService: LoaderService) {
      this.clientData =  JSON.parse(localStorage.getItem('AlluserData'))
      console.log(this.clientData)
  }

 async loadData(){
    this.inboxList = []
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      try{
       // this.httpService.getApi('Client/getClientByEmail/'+this.clientData.email).subscribe((res: any) => {
          this.httpService.postApi({email:this.clientData.email},'Client/getClientByEmail').subscribe(async(res: any) => {
          console.log(res)
          this.inboxList = res.client 
await this.getserviceType();
          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }
  clientData:any = {}
  ngOnInit() {
    //this.clientData =  JSON.parse(localStorage.getItem('AlluserData'))
    this.loadData();
  }
  add() {
    this.router.navigate(['inbox-add'])
  }
  details(data) {
    console.log(data)
    localStorage.setItem('inboxDetails', JSON.stringify(data));
    this.router.navigate(['/inbox-details']) 
  }


  getserviceType()
  {
    return new Promise(resolve => {
    
      this.inboxList.forEach(element => {
        element['servicetype']=  element['servicetype']==''?0:element['servicetype'];
          this.httpService.postApi(element['servicetype'], 'Servicetype/getServiceById/' + element['servicetype']).subscribe((res: any) => {
            if (res.success) {
             console.log(res)
               //this.projectStatus[element['status']] = res.success
              // console.log(this.projectStatus)
              this.serviceTypes[element['servicetype']]=res.success.servicetype;
            }
          });
        
      })
      resolve(1) 
        
    })
  }
}
