import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
// import { DatePipe } from '@angular/common';
//import { timeStamp } from 'console';


@Component({
  selector: 'app-certificate-add',
  templateUrl: './certificate-add.component.html',
  styleUrls: ['./certificate-add.component.css']
})
export class CertificateAddComponent implements OnInit {

  certificateForm: FormGroup;
  loading;
  certificateArray:any={}
  serviceTypes
  projects
  clients
  image; // to photo upload
  certificateTypes
  ownerPhoto;
  file: any ;
 
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { }

  ngOnInit() {
    // this.certificateForm = this.formBuilder.group({
    //             status: ['', [Validators.required]],
    //             client: ['', [Validators.required]],
    //             certificate: ['', [Validators.required]],
    //             serviceType: ['', [Validators.required]],
    //             project: ['', [Validators.required]],
    //             renewelDays: ['', [Validators.required]],
    //             issueDate: ['', [Validators.required]],
    //             isAvailable: ['', [Validators.required]],
    //         });
    this.httpService.getApi('Servicetype/getAllServices').subscribe((res: any) => {
      this.serviceTypes = res.success.servicetype;
      console.log( this.serviceTypes)
      
     });


     this.httpService.getApi('Client/getAllClients').subscribe((res: any) => {
      this.clients = res.success.client;
     
      console.log( this.clients)
     });



     
     
        this.httpService.getApi('certificatetype/getAllcertificatetype').subscribe((res: any) => {
          this.certificateTypes = res.success.certificatetype;
       
      console.log( this.certificateTypes)
   
    });
    //  this.httpService.getApi('Project/getAllProjects').subscribe((res: any) => {
    //   this.projects = res.success.project;
    //   console.log( this.projects)
    //  });

   
  }

  getDate(data)
  {
    console.log(data)
    var dta = new Date(data);
    
   // let latest_date =this.datepipe.transform(new Date(data), 'dd-MM-yyy');
    //console.log(latest_date);
  
    let date_ob = new Date();

    // adjust 0 before single digit date
    let date = ("0" + dta.getDate()).slice(-2);
    
    // current month
    let month = ("0" + (dta.getMonth() + 1)).slice(-2);
    
    // current year
    let year = dta.getFullYear();
    
    // prints date in YYYY-MM-DD format
    console.log("new",date + "-" + month + "-" + year);
  }

 // serviceTypeOnChange(data)
 // {
   // this.httpService.postApi(data,'Status/getstatusById/' +data).subscribe((res: any) => {
     // this.status = res.success;
  //    this.getProjects()
   //   console.log(this.status)
   //  });
  //}

  getClientEmail:any = "";
  clientonChange(clientId:any)
  {
    console.log(clientId);
    this.getClientEmail = "";
    this.getClientEmail = this.clients ? this.clients.filter((v)=> v.id == clientId)[0].email : "";

    
    // this.httpService.postApi(data,'Project/getprojectById/' +data).subscribe((res: any) => {
    //   this.projects = res.success;
    //   console.log(this.projects)
    //  });
    this.getProjects()
  }

  getProjects()
  {
    console.log("hi")
    if((this.certificateArray['serviceType']) && (this.certificateArray['client'])){
      let a=this.certificateArray['serviceType']
      let b=this.certificateArray['client']
    let  data=this.certificateArray;
      console.log(data)
      this.httpService.postApi(data,'Project/getprojectById/' +a +'/' +b).subscribe((res: any) => {
        this.projects = res.success;
        console.log("res",res.success)
       });
     // console.log(data)
    }
    else{
      console.log("res1","client doesnot have projects in selected service")
    }
    
  }

  submit(){
    this.certificateArray['createdAt']= new Date().getTime();
    this.certificateArray['isAvailable']="1";
    //this.certificateArray['certificate']=sessionStorage.getItem('certificate')
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{
        const formData = new FormData();
       
         formData.append("certificate", this.file);
         this.httpService.postApiForImageSending(formData, `/KCertificate/add?client=${this.certificateArray['client']}&serviceType=${this.certificateArray['serviceType']}&project=${this.certificateArray['project']}&issueDate=${this.certificateArray['issueDate']}&expiryDate=${this.certificateArray['expiryDate']}&renewelDays=${this.certificateArray['renewelDays']}&isAvailable=${this.certificateArray['isAvailable']}&createdAt=${this.certificateArray['createdAt']}`).subscribe((res) => {
        // this.httpService.postApi(this.certificateArray, 'KCertificate/add').subscribe((res) => {
          this.loaderService.hideLoader();
          if(res["success"]){

            this.alertService.presentAlert('Success','Successfully added','Okay');
            this.router.navigate(['/certificate-list'])
            this.sendEmail();
          }else{
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }

  sendEmail(){
    this.getClientEmail
    this.httpService.postApi("", `client/send_email?email=${this.getClientEmail}`).subscribe((res) => {

    },err => {

    })
  }

  onFileSelect(event) {

    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }


 
get dateOfExpiry()
{
   let x=this.certificateArray['issueDate']
   var dt = new Date(x);

  let y=this.certificateArray['renewelDays']

  if(x!=null && y!=null)
  {
    let z=new Date(dt.setMonth(dt.getMonth() + parseInt(y)))
    var strDate1 = z.toISOString().slice(0,10);
   // console.log(strDate1);
   var dt1=new Date(z);
  // let date_ob = new Date();

   // adjust 0 before single digit date
   let date = ("0" + dt1.getDate()).slice(-2);
   
   // current month
   let month = ("0" + (dt1.getMonth() + 1)).slice(-2);
   
   // current year
   let year = dt1.getFullYear();
   
   // prints date in YYYY-MM-DD format
   console.log("new",date + "-" + month + "-" + year);
   this.certificateArray['expiryDate']=date + "-" + month + "-" + year;
    //return z.toISOString().slice(0,10);
    return date + "-" + month + "-" + year;
  }
 
// this.certificateArray['expiryDate']=strDate1;
   
}



}
