import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-add-city',
  templateUrl: './add-city.component.html',
  styleUrls: ['./add-city.component.scss']
})
export class AddCityComponent implements OnInit {

  cityForm: FormGroup;
  loading;
  constructor(private formBuilder:FormBuilder, private router:Router,
  public util:UtilService,
     public httpService:HttpServiceService, public alertService:AlertService,
     public loaderService:LoaderService) { }

  ngOnInit() {
    this.cityForm = this.formBuilder.group({
                name: ['', [Validators.required]],
                isAvailable: ['1', [Validators.required]],
            });
   
  }


  submit(data){
    if (!this.cityForm.valid) {
			this.util.enableFromValidation(this.cityForm);
			return;
    }
    
    data['createdAt']= new Date().getTime();
    this.loaderService.showLoader('Adding Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, `/client/add_city?city_name=${data.name}`).subscribe((res) => {
          this.loaderService.hideLoader();
          if(res["success"]){
            this.alertService.presentAlert('Success','Successfully added','Okay');
            this.router.navigate(['/city-list'])
          }else{
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }    
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
    
  }
}
