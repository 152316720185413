import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-company-overview-list',
  templateUrl: './company-overview-list.component.html',
  styleUrls: ['./company-overview-list.component.scss']
})
export class CompanyOverviewListComponent implements OnInit {
  companyoverview: any = {};
  companyOverViewList:any = []
  json: any=[];
  constructor(private router: Router, 
    public httpService: HttpServiceService, public alertService: AlertService,
    public util:UtilService, public sanitizer: DomSanitizer,
    public loaderService: LoaderService) {

  }

   loadData(){
    this.loaderService.showLoader('Fetching Please wait ..').then(()=>{
      this.companyOverViewList = []
      try{
        this.httpService.getApi('client/company_overview_list').subscribe(async(res: any) => {
          this.companyOverViewList = res.data ? res.data : []
          // if (res.success) {
          //   console.log(res)
          //   this.json = res.success.image;
          //    console.log(this.json)
          //    await this.getname()
          // }
          this.loaderService.hideLoader();
         },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });
      }catch(e){
        this.loaderService.hideLoader();
      }
   
    });
  }

  ngOnInit() {
    this.loadData();
  }
  add() {
    this.router.navigate(['/company-overview-add'])
  }

  details(data) {
    localStorage.setItem('companyoverviewDetails', JSON.stringify(data));
    this.router.navigate(['/company-overview-details'])
  }


  getname()
  {
    console.log("companyoverview")
    return new Promise(resolve => {
      this.json.forEach(element => {
          this.httpService.postApi(element['name'], 'Client/getbyid/' + element['name']).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.companyoverview[element['name']] = res.success.name
               console.log(this.companyoverview)
            }
          });
      })
      resolve(1)      
    })
  }

}
