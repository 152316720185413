import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  presentAlert = async (header,message,buttonText) => {
	  alert(message)
		// const alert = await this.alertCtrl.create({
		// 	header: header,
		// 	subHeader: '',
		// 	message: message,
		// 	buttons: [ buttonText ]
		// });

		// await alert.present();
  };
  presentNetworkAlert = async () => {
	  alert('network issue')
	// const alert = await this.alertCtrl.create({
	// 	header: 'Oops',
	// 	subHeader: '',
	// 	message: 'Something went wrong, Please check your internet',
	// 	buttons: [ {
	// 		text: 'Try Again',
	// 		role: 'cancel',
	// 		handler: data => {
	// 		  window.location.reload();
	// 		}
	// 	  }, ]
	// });

	// await alert.present();
};
}
