import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {
  clientName:any=[];
  serviceTypes:any=[];
  project;
  cities:any=[];
  constructor( private router:Router,
    public httpService:HttpServiceService, public alertService:AlertService,
    public util:UtilService,
    public loaderService:LoaderService) {
    this.project = JSON.parse(localStorage.getItem('projectDetails'))
    console.log(this.project)
  }

  ngOnInit() {
    this.httpService.postApi(this.project.client, 'Client/getbyid/' + this.project.client).subscribe((res: any) => {
      if (res.success) {
        console.log(res)
        this.clientName=res.success.client[0];
      }
    });

    //serviceType
 
      
          this.httpService.postApi(this.project.serviceType, 'Servicetype/getServiceById/' + this.project.serviceType).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.serviceTypes = res.success.servicetype
               //console.log(this.services)
            }
          });
     
          //city
          this.httpService.postApi(this.project.city, 'Client/cityById/' + this.project.city).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
               this.cities = res.success.city
               //console.log(this.services)
            }
          });
    
  }

  toggleStatus(id,status){
    let data={
      isAvailable:status
    }
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Project/edit' + id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully updated','Okay');
            this.router.navigate(['/project-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })
  }

  delete(data) {
    this.loaderService.showLoader('Updating Please wait ..').then(()=>{
      try{
        this.httpService.postApi(data, 'Project/remove/'+data.id).subscribe((res: any) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('Success','Successfully deleted','Okay');
            this.router.navigate(['/project-list'])
          } else {
            this.alertService.presentAlert('Error',res["error"]["message"],'Okay');
          }
        },(err)=>{
          
          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
         });    
      }catch(e){
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error','Something went wrong please try again','Okay');
      }
    })

  }
  edit(data) {
    console.log(data)
    localStorage.setItem('Project-edit-data', JSON.stringify(data));
    this.router.navigate(['/project-edit'])
  }


}
