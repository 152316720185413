import { Component, OnInit } from '@angular/core';
//import { FirebaseDbService } from 'src/app/firebase-db.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/http-service.service';
import { UtilService } from 'src/app/util.service';
import { LoaderService } from 'src/app/loader.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-service-add',
  templateUrl: './service-add.component.html',
  styleUrls: ['./service-add.component.scss']
})
export class ServiceAddComponent implements OnInit {
  serviceForm: FormGroup;
  file: any

  constructor(private formBuilder: FormBuilder, private router: Router,
    public util: UtilService,
    public httpService: HttpServiceService, public alertService: AlertService,
    public loaderService: LoaderService) { }

  ngOnInit() {
    this.serviceForm = this.formBuilder.group({
      image: [''],
      description: [''],
      link: [''],
      name:['']
    });
  }

  fileName:any = ""
  onFileSelect(event) {
    this.fileName = "";
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.fileName = event.target.files[0].name

    }
  }

  submit(data) {
    
    console.log(this.serviceForm.value)
    this.loaderService.showLoader('Adding Please wait ..').then(() => {
      try {
        const formData = new FormData();

        formData.append('service_image', this.file);
        this.httpService.postApiForImageSending(formData, `/client/add_service?name=${data.name}&description=${data.description}&link=${data.link}`).subscribe((res) => {
          this.loaderService.hideLoader();
          if (res["success"]) {
            this.alertService.presentAlert('success', 'Successfully added', 'Okay');
            this.router.navigate(['/service-list'])
          } else {
            this.alertService.presentAlert('Error', res["error"]["success"], 'Okay');
          }
        }, (err) => {

          this.loaderService.hideLoader();
          this.alertService.presentNetworkAlert();
        });
      } catch (e) {
        this.loaderService.hideLoader();
        this.alertService.presentAlert('Error', 'Something went wrong please try again', 'Okay');
      }
    })

  }
}
